/*
 * File: FilterAppointment.tsx
 * Project: mint-portal
 * File Created: Tuesday, 22nd November 2022 7:34:00 am
 * Author: Sowmiya Ramesh (sowmiya.ramesh@mutualmobile.com)
 * -----
 * Last Modified: Tuesday, 20th December 2022 5:06:51 pm
 * Modified By: Sowmiya Ramesh (sowmiya.ramesh@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */
import React from 'react';
import { Typography, makeStyles } from '@material-ui/core';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Button } from '@mui/material';
import SearchDropdown from '../../shared/SearchDropdown/SearchDropdown';
import CheckIcon from '@mui/icons-material/Check';
import MuiToggleButton from '@mui/material/ToggleButton';
import zeplinColor from '../../../theme/zeplinColor';
import { FilterParams, IClinics } from '../../../model';
import { styled } from '@mui/material/styles';

type AppointmentStatus = {
  id: number;
  name: string;
};

interface FilterProps {
  open: boolean;
  onClick: () => void;
  onFilterClick: any;
  status: AppointmentStatus[];
  searchStatus: (params: any) => void;
  clinicLists: IClinics[];
  searchClinic: (params: any) => void;
  selectedStatus?: AppointmentStatus[];
  selectedClinics: IClinics[];
  selectInsurance: any;
}
const useStyles = makeStyles(() => ({
  paper: { minWidth: '600px' },
  displayColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  displayRow: {
    display: 'flex',
    flexDirection: 'row',
  },
}));

const ToggleButton = styled(MuiToggleButton)({
  height: '34px',
  color: zeplinColor.Background50,
  borderRadius: '4px',
  border: `1px solid ${zeplinColor.Background50}`,
  '&.Mui-selected, &.Mui-selected:hover': {
    color: `${zeplinColor.Primary}`,
    border: `1px solid ${zeplinColor.Primary}`,
    backgroundColor: zeplinColor.Surface,
  },
});

const FilterAppointment: React.FC<FilterProps> = ({
  open,
  onFilterClick,
  onClick,
  status,
  searchStatus,
  clinicLists,
  searchClinic,
  selectedStatus,
  selectedClinics,
  selectInsurance,
}) => {
  const classes = useStyles();
  const [statusSelected, setStatusSelected] = React.useState<any>(
    selectedStatus ? selectedStatus : [],
  );
  const [clinicSelected, setClinicSelected] = React.useState<any>(
    selectedClinics ? selectedClinics : [],
  );
  const [insuranceSelected, setInsuranceSelected] = React.useState<FilterParams | any>(
    selectInsurance || { yes: false, no: false },
  );
  const { yes, no } = insuranceSelected;

  const handleStatusSelection = (value: AppointmentStatus) => {
    setStatusSelected(value);
  };

  const handleClinicSelection = (value: IClinics) => {
    setClinicSelected(value);
  };

  const handleFilterOptions = (e: any) => {
    if (e.target.name !== undefined) {
      const name = e.target.name;
      setInsuranceSelected((prev: any) => ({
        ...prev,
        [name]: !insuranceSelected[name],
      }));
    }
  };

  const handleFilter = () => {
    onFilterClick(statusSelected, clinicSelected, insuranceSelected);
  };

  return (
    <div>
      <Dialog
        open={open}
        classes={{ paper: classes.paper }}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle>
          <Typography variant='h5' component={'span'}>
            Filter Appointments
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id='alert-dialog-description'
            className={classes.displayColumn}
            style={{ gap: '24px' }}
          >
            <div style={{ gap: '24px' }} className={classes.displayColumn}>
              <div className={classes.displayColumn}>
                <Typography component={'span'} variant='h6'>
                  Clinics
                </Typography>
                <Typography component={'span'} variant='body2'>
                  View appointments from one or multiple clinics
                </Typography>
              </div>
              <div style={{ gap: '16px' }} className={classes.displayRow}>
                <SearchDropdown
                  searchList={clinicLists}
                  label={'Select Clinics'}
                  onSelectHandler={handleClinicSelection}
                  onSearchHandler={searchClinic}
                  value={clinicSelected}
                />
              </div>
            </div>
            <div style={{ gap: '24px' }} className={classes.displayColumn}>
              <div className={classes.displayColumn}>
                <Typography component={'span'} variant='h6'>
                  Status
                </Typography>
                <Typography component={'span'} variant='body2'>
                  Filter appointments from one or multiple statuses
                </Typography>
              </div>
              <div style={{ gap: '16px' }} className={classes.displayRow}>
                <SearchDropdown
                  searchList={status}
                  label={'Select Status'}
                  onSelectHandler={handleStatusSelection}
                  onSearchHandler={searchStatus}
                  value={statusSelected}
                />
              </div>
            </div>
            <div style={{ gap: '24px' }} className={classes.displayColumn}>
              <div className={classes.displayColumn}>
                <Typography component={'span'} variant='h6'>
                  Insurance
                </Typography>
              </div>
              <div style={{ gap: '16px' }} className={classes.displayRow}>
                <ToggleButton
                  disableRipple
                  selected={yes}
                  name='yes'
                  onChange={handleFilterOptions}
                  value='checked'
                  style={{ width: '93px' }}
                >
                  {yes && <CheckIcon />}Yes
                </ToggleButton>
                <ToggleButton
                  disableRipple
                  selected={no}
                  name='no'
                  value='checked'
                  onChange={handleFilterOptions}
                  style={{ width: '80px' }}
                >
                  {no && <CheckIcon />}No
                </ToggleButton>
              </div>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button style={{ color: `${zeplinColor.Primary}` }} onClick={onClick}>
            Cancel
          </Button>
          <Button onClick={handleFilter} style={{ color: `${zeplinColor.Primary}` }}>
            <Typography>Filter Results</Typography>
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default FilterAppointment;