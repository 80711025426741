/*
 * File: AddStaff.tsx
 * Project: mint-portal
 * File Created: Thursday, 18th August 2022 6:46:01 pm
 * Author: Jackson Thounaojam (jackson.thounaojam@mutualmobile.com)
 * -----
 * Last Modified: Wednesday, 15th March 2023 1:13:53 am
 * Modified By: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */
import React, { useEffect, useState } from 'react';
import EmptyState from '../../../../shared/EmptyState/EmptyState';
import { IStaff, IStaffListResponse, IViewClinicDetailsData } from '../../../../../model';
import { Box, Typography } from '@material-ui/core';
import Loader from '../../../../shared/Loader/Loader';
import InfoTextBox from '../../../../shared/InfoText/InfoText';
import SearchDropdown from '../../../../shared/SearchDropdown/SearchDropdown';
import SnackBar from '../../../../shared/Snack-Bar/snackBar';
import { zeplinColor } from '../../../../../theme';
import { StaffService } from '../../../../../service/staff.service';
import _debounce from 'lodash/debounce';
import { ClinicService } from '../../../../../service/clinic.service';
import StaffOption from '../../../../shared/StaffOptions/StaffOptions';
import './ClinicStaff.css';
import ListPopup from '../../../../shared/ListPopup';
import { Pagination } from '@mui/material';
import { Constants } from '../../../../../utilities/constants';
import { makeStyles } from '@material-ui/core/styles';
import { useLocation } from 'react-router-dom';
import { Utilities } from '../../../../../utilities/utilities';

type staffProps = {
  updatedClinicDetails: IViewClinicDetailsData | undefined;
  handleCreateClinicBtn: (value: boolean) => void;
};

const useStyles = makeStyles(() => ({
  ul: {
    '& .MuiPaginationItem-root': {
      color: zeplinColor.Background70,
      fontWeight: 'bold',
      '&.Mui-disabled': {
        background: zeplinColor.Background90,
      },
      '&.Mui-selected': {
        color: zeplinColor.Primary,
        border: `1px solid ${zeplinColor.Primary}`,
        background: zeplinColor.Background,
        fontWeight: 'bold',
      },
    },
  },
  tableRow: {
    '&:hover': {
      backgroundColor: `${zeplinColor.Primary50} !important`,
      cursor: 'pointer',
    },
  },
}));

function ClinicStaff({ updatedClinicDetails, handleCreateClinicBtn }: staffProps) {
  const [staffList, setStaffList] = useState<IStaff[]>([]);
  const [showSnackbar, setShowSnackbar] = useState<boolean>(false);
  const [addedStaffList, setAddedStaffList] = useState<IStaff[]>([]);
  const [showSnackbarMessage, setShowSnackbarMessage] = useState<string>('');
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [showPopUpLoader, setShowPopUpLoader] = useState<boolean>(false);
  const [open, setOpen] = useState(false);
  const [currentStaff, setCurrentStaff] = useState('');
  const [prevStaff, setPrevStaff] = useState('');
  const [currentStaffList, setCurrentStaffList] = useState<IStaff[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [total, setTotal] = useState<number>(0);
  const [visited, setVisited] = useState<number[]>([]);
  const [adminTotal, setAdminTotal] = useState<number>(0);
  const [counter, setCounter] = useState(0);
  const [height, setHeight] = useState(220);
  const classes = useStyles();
  const route = useLocation();
  const [isForbiddenAccess, setForbiddenAccess] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getStaff = async (searchString?: string) => {
    try {
      const staffListQueryParams =
        searchString && searchString?.length > 2
          ? { searchString, notClinicId: updatedClinicDetails?.id }
          : { limit: 5, skip: 0, notClinicId: updatedClinicDetails?.id };
      const response: IStaffListResponse = await StaffService.getStaffList(staffListQueryParams);
      setStaffList(response?.data?.staff);
    } catch (err) {
      setShowSnackbar(true);
      if ((err as any)?.response?.status === Constants.API_STATUS_CODE.FORBIDDEN) {
        setForbiddenAccess(true);
      }
      setShowSnackbarMessage(
        typeof err === 'string' ? err : (err as any).response.data.error.message[0],
      );
    }
  };
  const onDataPageChange = (event: any, page: number) => {
    existingStaff(page);
    setVisited([...visited, page]);
  };
  useEffect(() => {
    if (updatedClinicDetails?.id) {
      getStaff('');
      existingStaff(1);
    }
    if (updatedClinicDetails?.isPartialData || !updatedClinicDetails) {
      handleCreateClinicBtn(true);
    } else {
      handleCreateClinicBtn(false);
    }
  }, [updatedClinicDetails?.id, updatedClinicDetails?.isPartialData]);

  const getAdminStaff = async () => {
    prevStaff !== currentStaff && setShowPopUpLoader(true);
    try {
      setPrevStaff(currentStaff);
      const skip = prevStaff !== currentStaff ? 0 : counter;
      prevStaff !== currentStaff
        ? setCounter(Constants.LIMIT)
        : setCounter(counter + Constants.LIMIT);
      const staffListQueryParams = {
        limit: Constants.LIMIT,
        skip,
        roleIds: [currentStaff === 'DSO' ? 1 : 2],
      };
      const response: IStaffListResponse = await StaffService.getStaffList(staffListQueryParams);
      prevStaff !== currentStaff
        ? setCurrentStaffList(response?.data?.staff)
        : setCurrentStaffList([...currentStaffList, ...response?.data?.staff]);
      setAdminTotal(response?.data?.total);
      setShowPopUpLoader(false);
    } catch (err) {
      setShowPopUpLoader(false);
      setShowSnackbar(true);
      if ((err as any)?.response?.status === Constants.API_STATUS_CODE.FORBIDDEN) {
        setForbiddenAccess(true);
      }
      setShowSnackbarMessage(
        typeof err === 'string' ? err : (err as any).response.data.error.message[0],
      );
    }
  };

  useEffect(() => {
    open && getAdminStaff();
  }, [open]);

  const existingStaff = async (page: number) => {
    setShowLoader(true);
    try {
      setCurrentPage(page ? page : 1);
      const staffListQueryParams = {
        limit: Constants.PAGINATION_OPTIONS.limit,
        skip: page ? (page - 1) * 10 : 0,
        clinicIds: [updatedClinicDetails?.id as string],
        roleIds: [3, 4],
      };
      const response: IStaffListResponse | '' = await StaffService.getStaffList(
        staffListQueryParams,
      );
      response && setTotal(response?.data?.total);

      response && setAddedStaffList(response?.data?.staff);

      setShowLoader(false);
    } catch (err) {
      setShowLoader(false);
      setShowSnackbar(true);
      setShowSnackbarMessage(
        typeof err === 'string' ? err : (err as any).response.data.error.message[0],
      );
    }
  };

  const onSelectStaff = async (inputValue: any) => {
    const existing = addedStaffList.filter(
      (d: IStaff) => !inputValue.filter((ref: IStaff) => ref.id === d.id)?.length,
    );
    existing?.length && remove(existing[0].id, existing[0].firstName);
    try {
      updatedClinicDetails &&
        (await ClinicService.editClinicStaff({
          clinicId: updatedClinicDetails?.id,
          action: existing?.length ? 'unassign' : 'assign',
          staffId: inputValue[inputValue?.length - 1].id,
        }));

      existingStaff(currentPage);
      getStaff();
      setShowSnackbar(true);
      setAddedStaffList(inputValue);
      setShowSnackbarMessage(`${inputValue[inputValue?.length - 1].firstName} is assigned`);
    } catch (err) {
      setShowSnackbar(true);
      setShowSnackbarMessage(
        typeof err === 'string' ? err : (err as any).response.data.error.message[0],
      );
    }
  };
  const onsearchStaff = async (searchVal: string) => {
    if (searchVal?.length > 2) {
      _debounce(getStaff(searchVal) as any, 2000);
    } else {
      _debounce(getStaff('') as any, 2000);
    }
  };

  const remove = async (id: string, name: string) => {
    setShowLoader(true);

    const val = addedStaffList.filter((val) => id !== val?.id);
    setAddedStaffList(val);

    updatedClinicDetails &&
      (await ClinicService.editClinicStaff({
        clinicId: updatedClinicDetails?.id,
        action: 'unassign',
        staffId: id.toString(),
      }));
    updatedClinicDetails && setShowSnackbar(true);
    setShowSnackbarMessage(`${name} unassigned`);
    if (currentPage !== 1) {
      Math.ceil(total / Constants.PAGINATION_OPTIONS.limit) > currentPage
        ? setCurrentPage(currentPage)
        : setCurrentPage(currentPage - 1);
      addedStaffList?.length > 1 ? existingStaff(currentPage) : existingStaff(currentPage - 1);
    } else {
      setCurrentPage(1);
      existingStaff(1);
    }
  };

  return (
    <>
      {isForbiddenAccess && (
        <section
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '712px',
            position: 'inherit',
            top: 0,
          }}
        >
          <EmptyState
            path={``}
            text={'Sorry, you don’t have access to this section'}
            subText={''}
            buttonText={''}
            showAddButton={false}
            image={true}
            removeImage={false}
            forbiddenAccess={true}
          />
        </section>
      )}
      <div>
        {!updatedClinicDetails?.id && !isForbiddenAccess ? (
          <section
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '712px',
            }}
          >
            <EmptyState
              path={'/'}
              text={Utilities.getTextSubTextForClinicEmptyState(updatedClinicDetails)?.text}
              subText={Utilities.getTextSubTextForClinicEmptyState(updatedClinicDetails)?.subText}
              buttonText={''}
              showAddButton={false}
              image={false}
            />
          </section>
        ) : (
          !isForbiddenAccess && (
            <Box>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '1rem',
                  margin: '20px 32px',
                  width: '100%',
                }}
              >
                {showLoader && <Loader margin={false} />}
                <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Box>
                    <Typography variant='h5'>Clinic Staff</Typography>
                  </Box>
                </Box>
                {route?.pathname?.split('/')[2] === 'view' ? (
                  <></>
                ) : (
                  <>
                    <Box style={{ marginBottom: '2rem' }}>
                      <InfoTextBox
                        content={''}
                        width={'91%'}
                        show={true}
                        staff={setOpen}
                        currentStaff={setCurrentStaff}
                      />
                    </Box>
                    <Box style={{ marginBottom: '2rem' }}>
                      <InfoTextBox
                        content={Constants.INFO_TEXTS.NEW_STAFF_IN_CLINIC}
                        width={'91%'}
                        show={true}
                      />
                    </Box>

                    <Box>
                      <SearchDropdown
                        searchList={staffList}
                        label={'Search Staff Name'}
                        onSelectHandler={onSelectStaff}
                        onSearchHandler={onsearchStaff}
                        value={addedStaffList}
                        width='91%'
                        staff
                      />
                    </Box>
                  </>
                )}
                {addedStaffList?.length ? (
                  addedStaffList?.map((d: IStaff, ind) =>
                    ind < Constants.PAGINATION_OPTIONS.limit ? (
                      <Box
                        style={{
                          backgroundColor: zeplinColor.Background97,
                          border: `1px solid transparent`,
                          width: '91%',
                        }}
                        className='selected-staff'
                        key={ind}
                      >
                        <StaffOption
                          valprop={d}
                          remove={remove}
                          view={route?.pathname?.split('/')[2] === 'view'}
                        />
                      </Box>
                    ) : (
                      ''
                    ),
                  )
                ) : !showLoader ? (
                  <EmptyState
                    path={'/clinics/edit'}
                    text={'No staff to show'}
                    subText={''}
                    buttonText={''}
                    showAddButton={false}
                    image={false}
                  />
                ) : (
                  ''
                )}
                {total ? (
                  <Pagination
                    count={
                      total <= Constants.PAGINATION_OPTIONS.limit
                        ? 1
                        : Math.ceil(total / Constants.PAGINATION_OPTIONS.limit)
                    }
                    onChange={onDataPageChange}
                    page={currentPage}
                    variant='outlined'
                    shape='rounded'
                    classes={{ outlined: classes.ul }}
                  />
                ) : (
                  ''
                )}

                <ListPopup
                  title={
                    currentStaff === 'marketing'
                      ? `${currentStaff[0]?.toUpperCase()}${currentStaff.slice(1)}` +
                        ' ' +
                        `Managers`
                      : `${currentStaff[0]?.toUpperCase()}${currentStaff.slice(1)}` + ' ' + `Admins`
                  }
                  description={
                    'Below is the list of staff who will have access to this clinic once it is created'
                  }
                  open={open}
                  assignedClinic={currentStaffList}
                  setOpen={setOpen}
                  staff
                  adminTotal={adminTotal}
                  getAdminStaff={getAdminStaff}
                  showLoader={showPopUpLoader}
                  setHeight={setHeight}
                  height={height}
                />
              </div>
              {showSnackbar && (
                <SnackBar
                  message={showSnackbarMessage}
                  show={showSnackbar}
                  setShow={setShowSnackbar}
                />
              )}
            </Box>
          )
        )}
      </div>
    </>
  );
}

export default ClinicStaff;
