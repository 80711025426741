/*
 * File: EmailField.tsx
 * Project: mint-portal
 * File Created: Thursday, 23rd June 2022 5:33:47 pm
 * Author: Deepankar Saini (deepankar.saini@mutualmobile.com)
 * -----
 * Last Modified: Friday, 18th August 2023 11:50:40 am
 * Modified By: Jackson Thounaojam (jackson.thounaojam@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */
import * as React from 'react';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import { zeplinColor } from '../../theme';
import CancelIcon from '@mui/icons-material/Cancel';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { makeStyles } from '@material-ui/core';
interface FieldProps {
  label?: string;
  value?: string | null;
  name?: string;
  iconChanged?: any;
  error?: boolean | undefined;
  helperText?: any;
  showIcon?: any;
  width?: any;
  placeholder?: any;
  style?: any;
  type?: string;
  disable?: boolean;
  multiline?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleClearEmail?: () => void;
  onEnter?: () => Promise<void>;
  inputValue?: { email: string; password: string };
  infinite?: boolean;
  id?: string;
  handleOpen?: any;
  open?: boolean;
}

const CustomTextField = styled(TextField)({
  '& .MuiTextField-root': {
    '& label': {
      color: zeplinColor.Primary,
    },
  },

  '& .MuiInput-underline:after': {
    borderBottomColor: zeplinColor.Primary,
  },
  '& label.Mui-error.Mui-focused': {
    color: zeplinColor.Error,
  },
  '& .Mui-error.MuiInput-underline:after': {
    borderBottomColor: zeplinColor.Error,
    color: zeplinColor.Error,
  },
  '& .MuiInputAdornment-root': {
    paddingBottom: '12px',
  },
  '& label.Mui-focused': {
    color: zeplinColor.Primary,
  },
});

const useStyles = makeStyles(() => ({
  label: {
    '& .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.MuiInputLabel-standard.MuiFormLabel-colorPrimary.MuiFormLabel-filled.css-1c2i806-MuiFormLabel-root-MuiInputLabel-root':
      {
        paddingLeft: '6px',
      },
    '& .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated': {
      paddingLeft: '6px',
      marginLeft: '5px',
    },
    '& .MuiInputBase-input': {
      padding: '0px 0px 16px 10px',
    },
    '& label.Mui-focused': {
      margin: '0px 0px 0px 16px',
    },
  },
}));

const Field: React.FC<FieldProps> = ({
  style,
  label,
  name,
  placeholder,
  width,
  value,
  iconChanged,
  error,
  helperText,
  showIcon,
  type = 'text',
  handleClearEmail,
  onChange,
  onEnter,
  inputValue,
  disable = false,
  multiline = false,
  infinite = false,
  id,
  handleOpen,
  open,
}) => {
  const styles = useStyles();
  const handleMouseDownEmail = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };
  const keyDownHandler = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.code === 'Enter' && inputValue?.email !== '' && inputValue?.password !== '') {
      event.preventDefault();
      onEnter && onEnter();
    }
  };

  return (
    <CustomTextField
      multiline={multiline}
      label={label}
      sx={{
        m: 1,
        width: width,
        backgroundColor: zeplinColor.Background,
      }}
      value={value}
      autoComplete={infinite ? 'off' : 'on'}
      name={name}
      error={error}
      helperText={helperText}
      placeholder={placeholder}
      style={style}
      className={style ? '' : styles.label}
      onChange={onChange}
      onKeyDown={keyDownHandler}
      disabled={disable}
      InputProps={{
        id: id ? id : 'input',
        onClick: () => {
          handleOpen(!open);
          open && document.getElementById(`${id}`)?.blur();
        },
        endAdornment: (
          <InputAdornment position='end'>
            {showIcon ? (
              <IconButton onClick={handleClearEmail} onMouseDown={handleMouseDownEmail}>
                {iconChanged ? (
                  <WarningAmberIcon sx={{ color: zeplinColor.Error }} />
                ) : (
                  <CancelIcon />
                )}
              </IconButton>
            ) : (
              ''
            )}
          </InputAdornment>
        ),
      }}
      type={type}
      variant='standard'
    />
  );
};

export default Field;
