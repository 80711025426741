/*
 * File: CustomProgressBar.tsx
 * Project: mint-portal
 * File Created: Sunday, 13th November 2022 1:43:35 pm
 * Author: Deepankar Saini (deepankar.saini@mutualmobile.com)
 * -----
 * Last Modified: Wednesday, 23rd August 2023 9:10:28 pm
 * Modified By: Sowmiya Ramesh (sowmiya.ramesh@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */
import * as React from 'react';
import Box from '@mui/material/Box';
import SquareRoundedIcon from '@mui/icons-material/SquareRounded';
import { Typography } from '@material-ui/core';
import './CustomProgressBar.css';

interface FieldProps {
  value: number;
  color1: string;
  color2: string;
  label1: string;
  percentage1: number;
  percentage2?: number;
  percentage3?: number;
  label2: string;
  label3?: string;
  color3?: string;
}

const CustomBars: React.FC<FieldProps> = ({
  color1,
  color2,
  label1,
  percentage1 = 0,
  percentage2 = 0,
  percentage3 = 0,
  label3,
  color3,
  label2,
}) => {

  const totalPercentage = percentage1 + percentage2 + percentage3;
  const remainingPercentage = 100 - totalPercentage;

  console.log(remainingPercentage);

  return (
    <Box style={{margin: '0px 24px 24px 24px'}}>
      <div className='progressBarContainer'>
        <div className='progressBar'>
          {percentage1 > 0 && (
            <div
              className='progressSegment'
              style={{
                width: `${percentage1}%`,
                backgroundColor: color1,
              }}
            />
          )}
          {percentage2 > 0 && (
            <div
              className='progressSegment'
              style={{
                width: `${percentage2}%`,
                backgroundColor: color2,
                left: `${percentage1}%`,
                borderEndEndRadius: (!label3 && !percentage3) ? '4px' : '0px',
                borderTopRightRadius: (!label3 && !percentage3) ?  '4px' : '0px',
              }}
            />
          )}
          {label3 && percentage3 > 0 && (
            <div
              className='progressSegment'
              style={{
                width: `${percentage3}%`,
                backgroundColor: color3,
                left: `${percentage1 + percentage2}%`,
                borderEndEndRadius: remainingPercentage > 0 ? '0px' : '4px',
                borderTopRightRadius: remainingPercentage > 0 ? '0px' : '4px',
              }}
            />
          )}
          {remainingPercentage > 0 && (
            <div
              className='progressSegment'
              style={{
                width: `${remainingPercentage}%`,
                backgroundColor: percentage1 === 0 ? color1 : percentage2 === 0 ? color2 : color3,
                left: `${totalPercentage}%`,
                borderEndEndRadius: '4px',
                borderTopRightRadius: '4px',
              }}
            />
          )}
        </div>
      </div>
      {/* )} */}
      <div
        style={{
          display: 'flex',
          gap: '48px',
          flexDirection: 'row',
          // marginLeft: 24,
          marginBottom: 24,
        }}
      >
        <div style={{ gap: '8px', display: 'flex' }}>
          <SquareRoundedIcon sx={{ color: color1 }} />
          <Typography variant='caption' color='textSecondary'>
            {label1} {percentage1 + '%'}
          </Typography>
        </div>
        <div style={{ gap: '8px', display: 'flex' }}>
          <SquareRoundedIcon sx={{ color: color2 }} />
          <Typography variant='caption' color='textSecondary'>
            {label2} {percentage2 + '%'}
          </Typography>
        </div>
        {label3 && (
          <div style={{ gap: '8px', display: 'flex' }}>
            <SquareRoundedIcon sx={{ color: color3 }} />
            <Typography variant='caption' color='textSecondary'>
              {label3} {percentage3 + '%'}
            </Typography>
          </div>
        )}
      </div>
    </Box>
  );
};

export default CustomBars;
