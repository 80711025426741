/*
 * File: Providers.tsx
 * Project: mint-portal
 * File Created: Thursday, 18th August 2022 6:46:53 pm
 * Author: Jackson Thounaojam (jackson.thounaojam@mutualmobile.com)
 * -----
 * Last Modified: Sunday, 19th February 2023 11:05:53 pm
 * Modified By: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */
import React, { useEffect, useState } from 'react';
import './Providers.css';
import EmptyState from '../../../../shared/EmptyState/EmptyState';
import {
  IProviderByClinicIdQueryParamOptions,
  IProviderByClinicIdRO,
  IProviderByClinicIdResponse,
  IProviderGetResponse,
  IProviderSavePayload,
  IClinicProviderDetailRO,
  IViewClinicDetailsData,
  IOpenDentalSyncStatusRO,
} from '../../../../../model';
import { ClinicService } from '../../../../../service/clinic.service';
import { Box, Typography } from '@material-ui/core';
import Buttons from '../../../../shared/Buttons';
import SearchDropdown from '../../../../shared/SearchDropdown/SearchDropdown';
import { Constants } from '../../../../../utilities/constants';
import Loader from '../../../../shared/Loader/Loader';
import SnackBar from '../../../../shared/Snack-Bar/snackBar';
import { useLocation } from 'react-router-dom';
import { Utilities } from '../../../../../utilities/utilities';
import InfoTextBox from '../../../../shared/InfoText/InfoText';

type providersProps = {
  updatedClinicDetails: IViewClinicDetailsData | undefined;
  handleCreateClinicBtn: (value: boolean) => void;
};

const Providers = ({ updatedClinicDetails, handleCreateClinicBtn }: providersProps) => {
  const route = useLocation();

  const [providerList, setProvidersList] = useState<IProviderByClinicIdResponse>({
    total: 0,
    providers: [],
  });
  const defaultProvider = {
    providerNumber: +'',
    providerCode: '',
    providerLastName: '',
    providerFirstName: '',
  };
  const [primaryProviderList, setPrimaryProviderList] = useState<IProviderGetResponse[]>([]);
  const [selectProvider, setSelectProvider] = useState<IProviderGetResponse>(
    updatedClinicDetails?.id ? updatedClinicDetails?.provider : defaultProvider,
  );
  const [selectPrimaryProvider, setSelectPrimaryProvider] = useState<IProviderGetResponse>(
    updatedClinicDetails?.id ? updatedClinicDetails?.primaryProvider : defaultProvider,
  );
  const [searchProvider, setSearchProvider] = useState('');
  const [showSnackbar, setShowSnackbar] = useState<boolean>(false);
  const [showSnackbarMessage, setShowSnackbarMessage] = useState<string>('');
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [openDentalSyncStatus, setOpenDentalSyncStatus] = useState<boolean>(false);

  const getOpenDentalSyncStatus = async () => {
    setShowLoader(true);
    try {
      let openDentalSyncStatusRO: IOpenDentalSyncStatusRO = {
        openDentalSync: false, // TODO: not checking this key because we have no server agent ready yet.
        operatories: false,
        procedureCodes: false,
        providers: false,
      };
      if (updatedClinicDetails?.id) {
        openDentalSyncStatusRO = await ClinicService.getOpenDentalSyncStatus(
          updatedClinicDetails.id,
        );
      }

      const isOpenDentalSyncDone = !!openDentalSyncStatusRO?.providers;
      setOpenDentalSyncStatus(isOpenDentalSyncDone);
      if (isOpenDentalSyncDone) {
        await getProviders(); // it is required, because we need to fetch the latest providers after sync done
      }
    } catch (error) {
      setShowSnackbar(true);
      setShowSnackbarMessage(
        typeof error === 'string' ? error : (error as any)?.response?.data?.error.message[0],
      );
    } finally {
      setShowLoader(false);
    }
  };

  useEffect(() => {
    if (openDentalSyncStatus) {
      // if open dental not in sync, then no need to call provider apis
      getProviders();
    }
  }, [searchProvider]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (
      updatedClinicDetails?.id &&
      updatedClinicDetails?.isDraft &&
      updatedClinicDetails?.openDentalConfig?.id
    ) {
      // GET OPEN DENTAL STATUS ONLY IF CLINIC IS IN DRAFT AND OPEN DENTAL CONFIG SET
      getOpenDentalSyncStatus();
    }
    if (updatedClinicDetails?.isDraft === false) {
      // if clinic is not in draft state, then no need to check open dental sync status
      setOpenDentalSyncStatus(true);
      getProviders();
    }
    if (openDentalSyncStatus) {
      // if open dental not in sync, then no need to call provider apis
      getProviders();
      if (updatedClinicDetails?.provider && updatedClinicDetails?.primaryProvider) {
        setSelectProvider(
          updatedClinicDetails?.id ? updatedClinicDetails?.provider : defaultProvider,
        );
        setSelectPrimaryProvider(
          updatedClinicDetails?.id ? updatedClinicDetails?.primaryProvider : defaultProvider,
        );
      }
    }
  }, [updatedClinicDetails?.id, openDentalSyncStatus]);

  const onSelectProvider = (inputValue: IProviderGetResponse) => {
    setSelectProvider(inputValue);
    inputValue === null
      ? setSelectPrimaryProvider(defaultProvider)
      : getPrimaryProviderList(providerList.providers, inputValue);
  };

  const onSearchProvider = (searchVal: string) => {
    setSearchProvider(searchVal);
  };

  const onSelectPrimaryProvider = (inputValue: IProviderGetResponse) => {
    setSelectPrimaryProvider(inputValue);
  };

  const getPrimaryProviderList = (
    providerList: IProviderGetResponse[],
    value: IProviderGetResponse,
  ) => {
    const filteredArr = providerList.filter((item) => {
      return item.providerNumber !== value.providerNumber;
    });
    setPrimaryProviderList(filteredArr);
  };

  const getProviders = async () => {
    if (updatedClinicDetails?.id) {
      try {
        const queryParam: IProviderByClinicIdQueryParamOptions =
          searchProvider.length > 2
            ? { searchString: searchProvider }
            : { limit: Constants.PAGINATION_OPTIONS.limit, skip: 0 };
        const listProviders: IProviderByClinicIdRO = await ClinicService.getProviderListByClinicId(
          queryParam,
          updatedClinicDetails?.id,
        );
        setProvidersList(() => listProviders.data);
        if (selectProvider) {
          getPrimaryProviderList(listProviders?.data?.providers, selectProvider);
        }
        setShowLoader(false);
      } catch (err) {
        setShowLoader(false);
        setShowSnackbar(true);
        setShowSnackbarMessage(
          typeof err === 'string' ? err : (err as any).response.data.error.message[0],
        );
      }
    }
  };

  const saveProviders = async () => {
    if (selectProvider && selectPrimaryProvider && updatedClinicDetails?.id) {
      setShowLoader(true);
      try {
        const providerPayload: IProviderSavePayload = {
          provider: selectProvider.providerNumber.toString(),
          primaryProvider: selectPrimaryProvider.providerNumber.toString(),
        };
        const saveProvidersRes: IClinicProviderDetailRO = await ClinicService.saveClinicProvider(
          updatedClinicDetails?.id,
          providerPayload,
        );
        if (saveProvidersRes.data && !saveProvidersRes.data.isPartialData) {
          handleCreateClinicBtn(false);
        }
        setShowLoader(false);
        setShowSnackbar(true);
        setShowSnackbarMessage('Providers Saved');
      } catch (err) {
        setShowLoader(false);
        setShowSnackbar(true);
        setShowSnackbarMessage(
          typeof err === 'string' ? err : (err as any).response.data.error.message[0],
        );
      }
    }
  };
  useEffect(() => {
    if (!updatedClinicDetails?.isPartialData) {
      if (selectProvider?.providerNumber && selectPrimaryProvider?.providerNumber) {
        handleCreateClinicBtn(false);
      } else {
        handleCreateClinicBtn(true);
      }
    }
  }, [selectProvider, selectPrimaryProvider]);
  return (
    <div>
      {!updatedClinicDetails?.id ||
      !updatedClinicDetails?.openDentalConfig?.id ||
      !openDentalSyncStatus ? (
          <section className='provider-empty-state'>
            <EmptyState
              path={'/'}
              text={Utilities.getTextSubTextForClinicEmptyState(updatedClinicDetails, true)?.text}
              subText={
                Utilities.getTextSubTextForClinicEmptyState(updatedClinicDetails, true)?.subText
              }
              buttonText={''}
              showAddButton={false}
              image={false}
            />
            {updatedClinicDetails?.openDentalConfig?.id && !openDentalSyncStatus && (
              <div style={{ position: 'absolute', top: '48.5rem' }}>
                <Buttons
                  text='refresh status'
                  width='170px'
                  disable={false}
                  onClick={getOpenDentalSyncStatus}
                />
              </div>
            )}
          </section>
        ) : (
          <div className='provider-container'>
            {showLoader && <Loader margin={true} />}
            <Typography variant='h6' style={{ marginBottom: 36 }}>
            Providers
            </Typography>
            <Box sx={{ margin: '32px auto' }}>
              <InfoTextBox
                content={Constants.INFO_TEXTS.WARNING_SAVE_CLINIC}
                width={'100%'}
                show={true}
              />
            </Box>
            <section style={{ display: 'flex', flexDirection: 'column', gap: 36, flex: 1 }}>
              <section>
                <div className=''>
                  <SearchDropdown
                    searchList={providerList?.providers}
                    width={'100%'}
                    label={'Provider'}
                    onSelectHandler={onSelectProvider}
                    onSearchHandler={onSearchProvider}
                    provider={true}
                    multiple={false}
                    value={selectProvider}
                    disable={route?.pathname?.split('/')[2] === 'view'}
                  // fixed={selectProvider ? true : false}
                  />
                </div>
              </section>
              {selectProvider && (
                <section>
                  <div className=''>
                    <SearchDropdown
                      searchList={primaryProviderList}
                      width={'100%'}
                      label={'Primary Provider'}
                      onSelectHandler={onSelectPrimaryProvider}
                      onSearchHandler={onSearchProvider}
                      provider={true}
                      multiple={false}
                      value={selectPrimaryProvider}
                      // fixed={selectPrimaryProvider ? true : false}
                      disable={
                        route?.pathname?.split('/')[2] === 'view'
                          ? true
                          : selectProvider
                            ? false
                            : true
                      }
                    />
                  </div>
                </section>
              )}
              {route?.pathname?.split('/')[2] === 'view' ? (
                <></>
              ) : (
                <section
                  style={{
                    alignSelf: 'flex-end',
                    marginTop: 'auto',
                  }}
                >
                  <Buttons
                    text='SAVE DETAILS'
                    width='160px'
                    disable={
                      selectProvider?.providerNumber && selectPrimaryProvider?.providerNumber
                        ? false
                        : true
                    }
                    onClick={saveProviders}
                  />
                </section>
              )}
            </section>
          </div>
        )}
      {showSnackbar && (
        <SnackBar message={showSnackbarMessage} show={showSnackbar} setShow={setShowSnackbar} />
      )}
    </div>
  );
};

export default Providers;
