/*
 * File: manage.ts
 * Project: mint-portal
 * File Created: Thursday, 29th September 2022 6:24:59 pm
 * Author: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Last Modified: Monday, 1st April 2024 7:58:36 pm
 * Modified By: Jackson Thounaojam (jackson.thounaojam@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */
import { BlockOutType, IOngoingBlockoutDetails } from './clinic.model';

import { IFileDetails } from './file.model';

export enum LegalDocumentType {
  TERMS_AND_CONDITIONS = 'TERMS_AND_CONDITIONS',
  PRIVACY_POLICY = 'PRIVACY_POLICY',
  REWARD_POLICY = 'REWARD_POLICY',
}
export interface IQueryParam {
  limit: number;
  skip: number;
}

export interface IListDSOBlockoutsQueryParam extends IQueryParam {
  currentDateTime?: string;
}

export interface IDsoBlockoutRO {
  data: IBlockoutListResponse;
  message: string;
}

export interface IBlockoutListResponse {
  blockouts: IOngoingBlockoutDetails[];
  total: number;
}

export interface IDsoBlockoutDetailRO {
  data: IOngoingBlockoutDetails;
  message: string;
}

export interface IBlockoutDetailResponse {
  blockouts: IOngoingBlockoutDetails;
  total: number;
}

export interface IDSOBlockout {
  title: string;
  allDay: boolean;
  startDate: string;
  endDate: string;
  startTime: string;
  endTime: string;
}

interface IDSOBlockoutResponse {
  id: number;
  title: string;
  allDay: boolean;
  type: BlockOutType;
  startDate: string;
  endDate: string;
  startTime: string;
  endTime: string;
}

export interface IDSOBlockoutRO {
  data: IDSOBlockoutResponse;
  message: string;
}

export interface IDSONppwRO {
  data: IDSONppw;
  message: string;
}

export interface IDSONppw {
  NPPWTitle: string;
  NPPWLink: string;
}

export interface IListVideosRO {
  message: string;
  data: IVideosDetailResponse[];
}

export interface IVideosDetailResponse {
  createdAt: string;
  updatedAt: string;
  id: number;
  title: string;
  link: string;
  displayOrder: number;
}

export interface IVideosUpdateOrder {
  mediaLinkIds: number[];
}

export interface ILandingImagesRO {
  data: IFileDetails[];
  message: string;
}
export interface ILandingImagesPayload {
  appImages: string[];
}
export interface IDSOLegalDocumentRO {
  data: IDSOLegalDocumentListResponse[];
  message: string;
}

export interface IDSOLegalDocumentListResponse {
  id: number;
  name: string;
  type: LegalDocumentType;
  updatedAt: string;
  staff: IStaffDetailsResponse;
  documentDetails: IFileDetails;
  url: string;
}

export interface IStaffDetailsResponse {
  id: string;
  firstName: string;
  lastName: string;
  profileImageDetails: IFileDetails;
}

export interface IUpdateLegalDocument {
  name: string;
  url: string;
  type: LegalDocumentType;
}

export interface IDSOPendingBalanceRO {
  data: IDSOPendingBalanceResponse;
  message: string;
}

export interface IDSOPendingBalanceResponse {
  id: number;
  showPendingBalance: boolean;
}
export interface IDSOAddRewardCategoryPayload {
  name: string;
}
export interface IReferralDetailRO {
  data: IReferralDetailResponse;
  message: string;
}

export interface IReferralDetailResponse {
  referredToPoints: number;
  referredByPoints: number;
}

export interface IListFaqRO {
  data: IFaqDetailResponse[];
  message: string;
}

export interface IFaqByIdRO {
  data: IFaqDetailResponse;
  message: string;
}

export interface IFaqDetailResponse extends IFaqAddPayload {
  createdAt: string;
  updatedAt: string;
  id: number;
  displayOrder: number;
}

export interface IFaqAddPayload {
  question: string;
  answer: string;
}

export interface IFaqUpdateOrder {
  faqIds: number[];
}

export interface IAddNotificationPayload {
  hours: number;
  scheduledTime?: string;
}
export interface IListNotificationCadenceRO {
  data: INotificationCadenceDetailResponse[];
  message: string;
}
export interface INotificationCadenceByIdRO {
  data: INotificationCadenceDetailResponse;
  message: string;
}

export interface INotificationCadenceDetailResponse {
  createdAt: string;
  updatedAt: string;
  id: number;
  hours: number;
  scheduledTime: string;
}

export interface IIconListRO {
  data: IIconDetailsResponse[];
  message: string;
}

export interface IIconDetailsResponse {
  createdAt: string;
  updatedAt: string;
  id: number;
  iconName: string;
  iconDetails: IFileDetails;
}

export interface IIconLibraryPayload {
  iconName: string;
  iconS3Key: string;
}

export interface IVideoLinkByIdRO {
  data: IVideoLinkDetailsResponse;
  message: string;
}

export interface IVideoLinkDetailsResponse extends IVideoLinkPayload {
  createdAt: string;
  updatedAt: string;
  id: number;
  displayOrder: number;
}

export interface IVideoLinkPayload {
  title: string;
  link: string;
}

export interface ILinkListRO {
  data: ILinkDetailResponse[];
  message: string;
}

export enum LINK_TYPE {
  SOCIAL_MEDIA = 'SOCIAL_MEDIA',
  OTHERS = 'OTHERS',
}

export interface ILinkDetailResponse {
  id: number;
  title: string;
  url: string;
  displayOrder: number;
  linkType: LINK_TYPE;
  imageDetails: IFileDetails;
}

export interface ILinkUpdateOrder {
  linkIds: number[];
}

export interface ILinkDetailRO {
  data: ILinkDetailResponse;
  message: string;
}

export interface ILinkAddPayload {
  title: string;
  url: string;
  s3Key: string;
}
