/*
 * File: setting.service.ts
 * Project: mint-portal
 * File Created: Thursday, 29th September 2022 8:41:07 pm
 * Author: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Last Modified: Monday, 1st April 2024 7:55:37 pm
 * Modified By: Jackson Thounaojam (jackson.thounaojam@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */

import {
  IAddNotificationPayload,
  ICategoryDetail,
  IDSOAddRewardCategoryPayload,
  IDSOBlockout,
  IDSOBlockoutRO,
  IDSOLegalDocumentRO,
  IDSONppw,
  IDSONppwRO,
  IDSOPendingBalanceRO,
  IDsoBlockoutDetailRO,
  IDsoBlockoutRO,
  IEmptyRO,
  IFaqAddPayload,
  IFaqByIdRO,
  IFaqUpdateOrder,
  IIconLibraryPayload,
  IIconListRO,
  ILandingImagesPayload,
  ILandingImagesRO,
  IListDSOBlockoutsQueryParam,
  IListFaqRO,
  IListNotificationCadenceRO,
  IListVideosRO,
  INotificationCadenceByIdRO,
  IReferralDetailRO,
  IReferralDetailResponse,
  IUpdateLegalDocument,
  IVideoLinkByIdRO,
  ILinkAddPayload,
  ILinkDetailRO,
  ILinkListRO,
  ILinkUpdateOrder,
  LINK_TYPE,
  IVideoLinkPayload,
  IVideosUpdateOrder,
} from '../model';

import { ApiConstants } from './api-constants';
import axios from '../core/axios';

export class ManageService {
  static getDsoBlockouts = async (
    queryParam: IListDSOBlockoutsQueryParam,
  ): Promise<IDsoBlockoutRO> => {
    return (
      await axios.get(
        ApiConstants.MANAGE_API.LIST_DSO_BLOCKOUT.URL() +
          ApiConstants.MANAGE_API.LIST_DSO_BLOCKOUT.QUERY_PARAMS(queryParam),
      )
    ).data;
  };

  static getDsoBlockoutDetail = async (id: string): Promise<IDsoBlockoutDetailRO> => {
    return await (
      await axios.get(ApiConstants.MANAGE_API.CRUD_DSO_BLOCKOUT_BY_ID.URL(id))
    ).data;
  };

  static deleteDsoBlockoutDetail = async (id: string): Promise<IEmptyRO> => {
    return await (
      await axios.delete(ApiConstants.MANAGE_API.CRUD_DSO_BLOCKOUT_BY_ID.URL(id))
    ).data;
  };

  static addDsoBlockoutDetail = async (payload: IDSOBlockout): Promise<IDSOBlockoutRO> => {
    return await (
      await axios.post(ApiConstants.MANAGE_API.ADD_DSO_BLOCKOUT.URL(), payload)
    ).data;
  };

  static updateDsoBlockoutDetail = async (
    id: string,
    updatePayload: IDSOBlockout,
  ): Promise<IDSOBlockoutRO> => {
    return await (
      await axios.put(ApiConstants.MANAGE_API.CRUD_DSO_BLOCKOUT_BY_ID.URL(id), updatePayload)
    ).data;
  };

  static updateDsoNppw = async (updatePayload: IDSONppw): Promise<IDSONppwRO> => {
    return await (
      await axios.put(ApiConstants.MANAGE_API.GET_AND_UPDATE_DSO_NPPW.URL(), updatePayload)
    ).data;
  };

  static getDsoNppw = async (): Promise<IDSONppwRO> => {
    return await (
      await axios.get(ApiConstants.MANAGE_API.GET_AND_UPDATE_DSO_NPPW.URL())
    ).data;
  };

  static getLandingImages = async (): Promise<ILandingImagesRO> => {
    return await (
      await axios.get(ApiConstants.MANAGE_API.GET_LANDING_IMAGES.URL())
    ).data;
  };
  static updateLandingImages = async (
    appImages: ILandingImagesPayload,
  ): Promise<ILandingImagesRO> => {
    return await (
      await axios.post(ApiConstants.MANAGE_API.UPDATE_LANDING_IMAGES.URL(), appImages)
    ).data;
  };

  static getDsoLegalDocument = async (): Promise<IDSOLegalDocumentRO> => {
    return await (
      await axios.get(ApiConstants.MANAGE_API.GET_AND_UPDATE_DSO_LEGAL_DOCUMENT.URL())
    ).data;
  };

  static updateDsoLegalDocument = async (
    updatePayload: IUpdateLegalDocument,
  ): Promise<IDSOLegalDocumentRO> => {
    return await (
      await axios.put(
        ApiConstants.MANAGE_API.GET_AND_UPDATE_DSO_LEGAL_DOCUMENT.URL(),
        updatePayload,
      )
    ).data;
  };

  static getDsoPendingBalance = async (): Promise<IDSOPendingBalanceRO> => {
    return await (
      await axios.get(ApiConstants.MANAGE_API.GET_PENDING_BALANCE.URL())
    ).data;
  };

  static updateDsoPendingBalance = async (
    pendingBalanceStatus: boolean,
  ): Promise<IDSOPendingBalanceRO> => {
    return await (
      await axios.put(ApiConstants.MANAGE_API.UPDATE_PENDING_BALANCE.URL(pendingBalanceStatus))
    ).data;
  };
  static addRewardCategory = async (name: IDSOAddRewardCategoryPayload): Promise<IEmptyRO> => {
    return await (
      await axios.post(ApiConstants.MANAGE_API.ADD_REWARD_CATEGORY.URL(), name)
    ).data;
  };
  static getReferralDetails = async (): Promise<IReferralDetailRO> => {
    return await (
      await axios.get(ApiConstants.MANAGE_API.GET_AND_UPDATE_REFERRAL_DETAILS.URL())
    ).data;
  };

  static updateReferralDetails = async (
    referralPayload: IReferralDetailResponse,
  ): Promise<IReferralDetailRO> => {
    return await (
      await axios.put(
        ApiConstants.MANAGE_API.GET_AND_UPDATE_REFERRAL_DETAILS.URL(),
        referralPayload,
      )
    ).data;
  };

  static getRewardCategoryById = async (categoryId: number): Promise<ICategoryDetail> => {
    return await (
      await axios.get(ApiConstants.MANAGE_API.REWARD_CATEGORY_BY_ID.URL(categoryId))
    ).data.data;
  };
  static updateRewardCategoryById = async (
    rewardPayload: IDSOAddRewardCategoryPayload,
    categoryId: number,
  ): Promise<ICategoryDetail> => {
    return await (
      await axios.put(ApiConstants.MANAGE_API.REWARD_CATEGORY_BY_ID.URL(categoryId), rewardPayload)
    ).data;
  };
  static deleteRewardCategoryById = async (categoryId: number): Promise<IEmptyRO> => {
    return await (
      await axios.delete(ApiConstants.MANAGE_API.REWARD_CATEGORY_BY_ID.URL(categoryId))
    ).data;
  };

  static getFaqList = async (): Promise<IListFaqRO> => {
    return await (
      await axios.get(ApiConstants.MANAGE_API.GET_FAQ_LIST.URL())
    ).data;
  };

  static getFaqById = async (faqId: number): Promise<IFaqByIdRO> => {
    return await (
      await axios.get(ApiConstants.MANAGE_API.GET_FAQ_BY_ID.URL(faqId))
    ).data;
  };

  static deleteFaqById = async (faqId: number): Promise<IEmptyRO> => {
    return await (
      await axios.delete(ApiConstants.MANAGE_API.DELETE_FAQ_BY_ID.URL(faqId))
    ).data;
  };

  static addFaq = async (faqPayload: IFaqAddPayload): Promise<IEmptyRO> => {
    return await (
      await axios.post(ApiConstants.MANAGE_API.ADD_FAQ.URL(), faqPayload)
    ).data;
  };
  static editFaq = async (faqId: number, faqPayload: IFaqAddPayload): Promise<IEmptyRO> => {
    return await (
      await axios.put(ApiConstants.MANAGE_API.EDIT_FAQ_BY_ID.URL(faqId), faqPayload)
    ).data;
  };
  static updateFaqOrder = async (faqPayload: IFaqUpdateOrder): Promise<IListFaqRO> => {
    return await (
      await axios.put(ApiConstants.MANAGE_API.UPDATE_FAQ_ORDER.URL(), faqPayload)
    ).data;
  };

  static getAppointmentNotificationsList = async (): Promise<IListNotificationCadenceRO> => {
    return await (
      await axios.get(ApiConstants.MANAGE_API.GET_APPOINTMENT_NOTIFICATIONS_LIST.URL())
    ).data;
  };

  static getAppointmentNotificationById = async (
    id: number,
  ): Promise<INotificationCadenceByIdRO> => {
    return await (
      await axios.get(ApiConstants.MANAGE_API.GET_APPOINTMENT_NOTIFICATION_BY_ID.URL(id))
    ).data;
  };

  static addAppointmentNotifications = async (
    payload: IAddNotificationPayload,
  ): Promise<IEmptyRO> => {
    return await (
      await axios.post(ApiConstants.MANAGE_API.ADD_APPOINTMENT_NOTIFICATIONS.URL(), payload)
    ).data;
  };

  static editAppointmentNotification = async (
    payload: IAddNotificationPayload,
    id: number,
  ): Promise<IEmptyRO> => {
    return await (
      await axios.put(ApiConstants.MANAGE_API.EDIT_APPOINTMENT_NOTIFICATIONS.URL(id), payload)
    ).data;
  };

  static deleteNotificationById = async (cadenceId: number): Promise<IEmptyRO> => {
    return await (
      await axios.delete(ApiConstants.MANAGE_API.DELETE_NOTIFICATION_BY_ID.URL(cadenceId))
    ).data;
  };

  static getServiceIconList = async (): Promise<IIconListRO> => {
    return await (
      await axios.get(ApiConstants.MANAGE_API.GET_SERVICES_ICON_LIST.URL())
    ).data;
  };

  static addServiceIcon = async (payload: IIconLibraryPayload): Promise<IEmptyRO> => {
    return await (
      await axios.post(ApiConstants.MANAGE_API.ADD_SERVICES_ICON.URL(), payload)
    ).data;
  };

  static deleteIconById = async (iconId: number): Promise<IEmptyRO> => {
    return await (
      await axios.delete(ApiConstants.MANAGE_API.DELETE_AND_UPDATE_ICON_BY_ID.URL(iconId))
    ).data;
  };

  static updateIconById = async (
    id: number,
    updatePayload: IIconLibraryPayload,
  ): Promise<IEmptyRO> => {
    return await (
      await axios.put(ApiConstants.MANAGE_API.DELETE_AND_UPDATE_ICON_BY_ID.URL(id), updatePayload)
    ).data;
  };

  static getVideosList = async (): Promise<IListVideosRO> => {
    return await (
      await axios.get(ApiConstants.MANAGE_API.GET_VIDEOS_LIST.URL())
    ).data;
  };

  static addVideoLink = async (payload: IVideoLinkPayload): Promise<IEmptyRO> => {
    return await (
      await axios.post(ApiConstants.MANAGE_API.ADD_VIDEO_LINK.URL(), payload)
    ).data;
  };

  static getVideoLinkById = async (id: number): Promise<IVideoLinkByIdRO> => {
    return await (
      await axios.get(ApiConstants.MANAGE_API.VIEW_EDIT_DELETE_VIDEO_LINK_BY_ID.URL(id))
    ).data;
  };

  static deleteVideoLinkById = async (videoId: number): Promise<IEmptyRO> => {
    return await (
      await axios.delete(ApiConstants.MANAGE_API.VIEW_EDIT_DELETE_VIDEO_LINK_BY_ID.URL(videoId))
    ).data;
  };

  static editVideoLink = async (videoId: number, payload: IVideoLinkPayload): Promise<IEmptyRO> => {
    return await (
      await axios.put(
        ApiConstants.MANAGE_API.VIEW_EDIT_DELETE_VIDEO_LINK_BY_ID.URL(videoId),
        payload,
      )
    ).data;
  };

  static updateVideosOrder = async (payload: IVideosUpdateOrder): Promise<IListVideosRO> => {
    return await (
      await axios.put(ApiConstants.MANAGE_API.UPDATE_VIDEOS_ORDER.URL(), payload)
    ).data;
  };

  static getLinkList = async (linkType: LINK_TYPE): Promise<ILinkListRO> => {
    return await (
      await axios.get(ApiConstants.MANAGE_API.GET_LINK_LIST.URL(linkType))
    ).data;
  };

  static updateOtherLinkListOrder = async (
    linkIdPayload: ILinkUpdateOrder,
  ): Promise<ILinkListRO> => {
    return await (
      await axios.put(ApiConstants.MANAGE_API.UPDATE_OTHER_LIST_ORDER.URL(), linkIdPayload)
    ).data;
  };

  static getLinkById = async (linkId: number): Promise<ILinkDetailRO> => {
    return await (
      await axios.get(ApiConstants.MANAGE_API.VIEW_EDIT_DELETE_LINK_BY_ID.URL(linkId))
    ).data;
  };

  static deleteLinkById = async (linkId: number): Promise<IEmptyRO> => {
    return await (
      await axios.delete(ApiConstants.MANAGE_API.VIEW_EDIT_DELETE_LINK_BY_ID.URL(linkId))
    ).data;
  };

  static addLink = async (payload: ILinkAddPayload): Promise<IEmptyRO> => {
    return await (
      await axios.post(ApiConstants.MANAGE_API.ADD_LINK.URL(), payload)
    ).data;
  };

  static updateLink = async (payload: ILinkAddPayload, linkId: number): Promise<ILinkDetailRO> => {
    return await (
      await axios.put(ApiConstants.MANAGE_API.VIEW_EDIT_DELETE_LINK_BY_ID.URL(linkId), payload)
    ).data;
  };
}
