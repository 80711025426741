/*
 * File: InfiniteDropDown.tsx
 * Project: mint-portal
 * File Created: Thursday, 17th August 2023 1:12:19 pm
 * Author: Jackson Thounaojam (jackson.thounaojam@mutualmobile.com)
 * -----
 * Last Modified: Monday, 21st August 2023 11:25:27 am
 * Modified By: Jackson Thounaojam (jackson.thounaojam@mutualmobile.com)
 * -----
 * Copyright 2020 - 2023 Mutual Mobile, Mutual Mobile
 */
import { Box } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { zeplinColor } from '../../../theme';
import Field from '../CustomTextField';

type IProp = {
  infinite: string;
  searchList: any;
  dataLength?: any;
  hasMore: string;
  next: any;
  value: any;
  handleOpen: any;
  label: any;
  open: boolean;
  disable: boolean;
  name: string;
  zIndex: string;
  onSelectHandler: (e: any, select?: boolean) => void;
  onSearchHandler?: (e: any) => void;
  search?: string;
  clearSearch?: boolean;
  setFiltered?: React.Dispatch<any>;
};
const InfiniteDropDown = ({
  infinite,
  searchList,
  dataLength,
  hasMore,
  next,
  value,
  handleOpen,
  open,
  label,
  disable,
  name,
  zIndex,
  onSelectHandler,
  onSearchHandler,
  search,
  clearSearch,
  setFiltered,
}: IProp) => {
  const handleChange = (event?: any, value?: any) => {
    console.log(value);
    if (event.key !== 'Backspace') {
      infinite === 'location' ? onSelectHandler(value, true) : onSelectHandler(value);
    }
  };
  return (
    <div style={{ position: 'relative', width: '100%' }} id='dropdown'>
      <div style={{ marginBottom: -8 }}>
        <Field
          width={'100%'}
          name={name}
          id={name}
          label={label}
          placeholder={value ? '' : label}
          showIcon={false}
          style={{ width: '100%', marginLeft: 0 }}
          infinite={true}
          value={
            clearSearch
              ? ''
              : search && open
                ? search
                : value
                  ? infinite === 'location'
                    ? value?.title
                    : value?.accountName
                  : ''
          }
          onChange={(e: any) => {
            onSearchHandler && onSearchHandler(e);
          }}
          disable={disable}
          handleOpen={handleOpen}
          open={open}
        />
      </div>
      {open ? (
        <Paper elevation={8} style={{ position: 'absolute', zIndex: zIndex, width: '100%' }}>
          <InfiniteScroll
            dataLength={dataLength}
            next={() => {
              infinite === 'location'
                ? next('', false, hasMore)
                : infinite === 'location'
                  ? next(hasMore)
                  : '';
            }}
            hasMore={hasMore ? true : false}
            loader={
              <h4
                style={{
                  marginTop: '5px',
                  paddingLeft: '1rem',
                  // paddingLeft: '1rem',
                  fontSize: 16,
                  fontWeight: 600,
                }}
              >
                Loading...
              </h4>
            }
            height={dataLength > 2 ? 150 : dataLength > 1 ? 100 : 50}
          >
            {dataLength ? (
              searchList?.map((val: any, ind: number) => (
                <Box
                  key={ind}
                  style={{
                    padding: '0.7rem 0rem 0.7rem 1rem',
                    // paddingLeft: '1rem',
                    cursor: 'pointer',
                    position: 'relative',
                    backgroundColor: val?.name === value?.name ? zeplinColor.Blue01 : '',
                    zIndex: 10,
                    fontSize: 16,
                    fontWeight: 500,
                  }}
                  onClick={(e: any) => {
                    handleChange(e, val);
                    setFiltered && setFiltered([]);
                  }}
                >
                  {infinite === 'location' ? val?.title : val?.accountName}
                </Box>
              ))
            ) : (
              <Box
                style={{
                  padding: '0.7rem 0rem 0.7rem 1rem',
                  // paddingLeft: '1rem',
                  cursor: 'pointer',
                  position: 'relative',
                  zIndex: 10,
                  fontSize: 16,
                  fontWeight: 500,
                  color: zeplinColor?.Background05,
                }}
              >
                No options
              </Box>
            )}
          </InfiniteScroll>
        </Paper>
      ) : (
        ''
      )}
    </div>
  );
};

export default InfiniteDropDown;
