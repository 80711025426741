/*
 * File: sessionStorage.ts
 * Project: mint-portal
 * File Created: Monday, 7th November 2022 6:36:20 pm
 * Author: Jackson Thounaojam (jackson.thounaojam@mutualmobile.com)
 * -----
 * Last Modified: Friday, 12th April 2024 12:21:55 am
 * Modified By: Jackson Thounaojam (jackson.thounaojam@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */
import { createContext, useContext } from 'react';
import { IUserProfileResponse } from '../model';
export type GlobalContent = {
  userData: IUserProfileResponse;
  setUserData: (c: any) => void; // React.Dispatch<React.SetStateAction<IUserProfileResponse>>
};
export const MyGlobalContext = createContext<GlobalContent>({
  userData: {
    createdAt: '',
    id: '',
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    isActive: false,
    isEditable: false,
    status: '',
    roles: [],
    isAllClinicsAccess: false,
    clinics: [],
    profileImageDetails: { expiresInSeconds: 0, isSignedURL: false, location: '', s3Key: '' },
  },
  setUserData: () => {
    return;
  },
});
export const useGlobalContext = () => useContext(MyGlobalContext);
