/*
 * File: ListClinic.tsx
 * Project: mint-portal
 * File Created: Thursday, 18th August 2022 1:32:21 pm
 * Author: Sowmiya Ramesh (sowmiya.ramesh@mutualmobile.com)
 * -----
 * Last Modified: Thursday, 29th August 2024 8:27:08 pm
 * Modified By: Jackson Thounaojam (jackson.thounaojam@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */
interface FilterArray {
  id: number;
  label: string;
  value: boolean;
  type: string;
}

import './ListClinic.css';

import { Button, Chip, Pagination, Paper } from '@mui/material';
import { IClinicList, IClinics } from '../../../../model';
import React, { FC, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';

import CircleIcon from '@mui/icons-material/Circle';
import { Constants } from '../../../../utilities/constants';
import CustomToolTip from '../../../shared/CustomToolTip';
import EmptyState from '../../../shared/EmptyState/EmptyState';
import EventAvailableOutlinedIcon from '@mui/icons-material/EventAvailableOutlined';
import EventBusyOutlinedIcon from '@mui/icons-material/EventBusyOutlined';
import getPermissionForModule from '../../../../utilities/getPermission';
import { makeStyles } from '@material-ui/core/styles';
import { useNavigate } from 'react-router-dom';
import { zeplinColor } from '../../../../theme';
interface FilterArray {
  id: number;
  label: string;
  value: boolean;
  type: string;
}

interface IProps {
  clinicList: IClinicList;
  onPageChangeHandler: any;
  currentPage: number;
  onFilterClear: any;
  onFilterOptionDel: any;
  selectedChipOption: FilterArray[] | null | undefined;
}

interface ClinicListColumn {
  id: 'clinic_name' | 'app_listing' | 'bookings' | 'open_dental_status';
  label: string;
  minWidth?: number;
  align?: 'right';
  format?: (value: number) => string;
}

const clinicListColumns: ClinicListColumn[] = [
  { id: 'clinic_name', label: 'Clinic Name', minWidth: 300 },
  { id: 'app_listing', label: 'App Listing', minWidth: 150 },
  { id: 'bookings', label: 'Bookings', minWidth: 150 },
  { id: 'open_dental_status', label: 'Open Dental Status', minWidth: 150 },
];

const useStyles = makeStyles(() => ({
  ul: {
    '& .MuiPaginationItem-root': {
      color: zeplinColor.Background70,
      fontWeight: 'bold',
      '&.Mui-disabled': {
        background: zeplinColor.Background90,
      },
      '&.Mui-selected': {
        color: zeplinColor.Primary,
        border: `1px solid ${zeplinColor.Primary}`,
        background: zeplinColor.Background,
        fontWeight: 'bold',
      },
    },
  },
  tableRow: {
    '&:hover': {
      backgroundColor: `${zeplinColor.Primary50} !important`,
      cursor: 'pointer',
    },
  },
}));

const ListClinic: FC<IProps> = ({
  clinicList,
  onPageChangeHandler,
  currentPage,
  onFilterClear,
  onFilterOptionDel,
  selectedChipOption,
}) => {
  const [dataPage, setDataPage] = useState<number>(currentPage);
  const classes = useStyles();
  const getPermission = getPermissionForModule();
  const permission = getPermission.permissions.Clinics;
  const onDataPageChange = (event: React.ChangeEvent<unknown>, page: number) => {
    setDataPage(page);
    onPageChangeHandler(page);
  };
  const navigate = useNavigate();
  const [selectedChips, setSelectedChips] = useState<FilterArray[] | null | undefined>(
    selectedChipOption,
  );
  const clearAllFilter = () => {
    setSelectedChips(null);
    onFilterClear({
      active: false,
      offline: false,
      accepting: false,
      notAccepting: false,
      connected: false,
      disconnected: false,
    });
  };

  const onFilterOptionChange = (chipToDelete: any) => {
    if (selectedChips?.filter((chip: any) => chip.id !== chipToDelete.id).length === 0) {
      setSelectedChips(null);
      onFilterClear({
        active: false,
        offline: false,
        accepting: false,
        notAccepting: false,
        connected: false,
        disconnected: false,
      });
    } else {
      setSelectedChips((chips: any) => chips?.filter((chip: any) => chip.id !== chipToDelete.id));
      onFilterOptionDel(chipToDelete);
    }
  };
  return (
    <>
      <div style={{ marginLeft: '24px', display: 'flex', flexDirection: 'row', gap: '8px' }}>
        {!!selectedChips &&
          selectedChips?.map((data: FilterArray) => {
            return (
              <Chip
                style={{ textTransform: 'capitalize' }}
                key={data.id}
                label={
                  data.label === 'active1'
                    ? 'active'
                    : data.label === 'notAccepting'
                      ? 'Not Accepting'
                      : data.label
                }
                onDelete={() => onFilterOptionChange(data)}
              />
            );
          })}
        {selectedChips?.length && (
          <Button onClick={clearAllFilter} variant='text' style={{ color: zeplinColor.Primary }}>
            Clear all
          </Button>
        )}
      </div>
      {clinicList.total && clinicList.clinics.length > 0 ? (
        <>
          <Paper className='containerClinicDiv' sx={{ borderRadius: '16px' }}>
            <TableContainer>
              <Table aria-label='caption table'>
                <TableHead>
                  <TableRow>
                    {clinicListColumns.map((column) => (
                      <TableCell key={column.id} align={column.align}>
                        <Typography style={{ color: zeplinColor.Background50 }} variant='subtitle1'>
                          {column.label}
                        </Typography>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {clinicList.total &&
                    clinicList.clinics.length &&
                    clinicList.clinics.map((clinicObj: IClinics) => {
                      return (
                        <TableRow
                          hover
                          classes={{ hover: classes.tableRow }}
                          key={clinicObj.id}
                          onClick={() =>
                            navigate({
                              pathname: `/clinics/detail/${clinicObj.id}`,
                            })
                          }
                        >
                          <TableCell>
                            <CustomToolTip title={clinicObj.name} width={'275px'} />
                          </TableCell>
                          <TableCell>
                            {clinicObj.appListing ? (
                              <section className='activeStatus'>
                                <CircleIcon
                                  style={{
                                    width: '12px',
                                    height: '12px',
                                    color: zeplinColor.Primary,
                                  }}
                                />
                                <Typography>Active</Typography>
                              </section>
                            ) : (
                              <section className='activeStatus'>
                                <CircleIcon
                                  style={{
                                    width: '12px',
                                    height: '12px',
                                    color: zeplinColor.Background70,
                                  }}
                                />
                                <Typography>Offline</Typography>
                              </section>
                            )}
                          </TableCell>
                          <TableCell>
                            {clinicObj.bookingStatus === 'accepting' ? (
                              <section className='activeStatus'>
                                <EventAvailableOutlinedIcon
                                  style={{
                                    width: '28px',
                                    height: '28px',
                                    color: zeplinColor.Primary,
                                  }}
                                />
                                <Typography>Accepting</Typography>
                              </section>
                            ) : (
                              <section className='activeStatus'>
                                <EventBusyOutlinedIcon
                                  style={{
                                    width: '28px',
                                    height: '28px',
                                    color: zeplinColor.Error,
                                  }}
                                />
                                <Typography>Not Accepting</Typography>
                              </section>
                            )}
                          </TableCell>
                          <TableCell>
                            {clinicObj.serverAgentStatus ? (
                              <Typography
                                className='openDentalStatus'
                                style={{
                                  color: zeplinColor.Primary,
                                  backgroundColor: zeplinColor.Primary50,
                                }}
                              >
                                Connected
                              </Typography>
                            ) : (
                              <Typography
                                className='openDentalStatus'
                                style={{
                                  color: zeplinColor.Error,
                                  backgroundColor: zeplinColor.Error50,
                                }}
                              >
                                Disconnected
                              </Typography>
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
          <Pagination
            count={
              clinicList.total <= Constants.PAGINATION_OPTIONS.limit
                ? 1
                : Math.ceil(clinicList.total / Constants.PAGINATION_OPTIONS.limit)
            }
            onChange={onDataPageChange}
            page={dataPage}
            variant='outlined'
            shape='rounded'
            className='pagination'
            classes={{ outlined: classes.ul }}
          />
        </>
      ) : (
        clinicList?.total === 0 && (
          <section style={{ marginTop: '15%' }}>
            <EmptyState
              path={'/clinics/add'}
              text={'No clinics to show'}
              subText={permission.create ? 'Start by adding a new clinic.' : ''}
              buttonText={permission.create ? 'ADD NEW CLINIC' : ''}
              showAddButton={permission.create ? true : false}
              image={true}
            />
          </section>
        )
      )}
    </>
  );
};

export default ListClinic;
