/*
 * File: AddBlockout.tsx
 * Project: mint-portal
 * File Created: Friday, 16th September 2022 2:56:48 pm
 * Author: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Last Modified: Tuesday, 14th March 2023 7:41:03 pm
 * Modified By: Sowmiya Ramesh (sowmiya.ramesh@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */

import './AddBlockout.css';

import {
  BlockOutType,
  IAddBlockoutPayload,
  IClinicBlockoutDetailResponse,
  IClinicOperatories,
  IClinicOperatoriesRO,
} from '../../../../model';
import {
  Button,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  Toolbar,
} from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import { Switch, Typography, makeStyles } from '@material-ui/core';
import { formatTime, getTime } from '../../../../utilities/getTime';
import moment, { Moment } from 'moment';
import { useLocation, useNavigate } from 'react-router-dom';

import BreadCrumbs from '../../../shared/BreadCrumbs/BreadCrumbs';
import Buttons from '../../../shared/Buttons';
import { ClinicService } from '../../../../service/clinic.service';
import { Constants } from '../../../../utilities/constants';
import CustomDatePicker from '../../../shared/CustomDatePicker';
import Field from '../../../shared/CustomTextField';
import Loader from '../../../shared/Loader/Loader';
import SearchDropdown from '../../../shared/SearchDropdown/SearchDropdown';
import SnackBar from '../../../shared/Snack-Bar/snackBar';
import { zeplinColor } from '../../../../theme';

type editProps = {
  edit?: boolean;
  blockoutDetail?: IClinicBlockoutDetailResponse;
  setUpdatePayload?: React.Dispatch<React.SetStateAction<IAddBlockoutPayload | undefined>>;
  updateLoader?: boolean;
  setEditBtn?: React.Dispatch<React.SetStateAction<boolean>>;
};

const useStyles = makeStyles(() => ({
  inputLabelRoot: {
    '&.MuiFormLabel-root': {
      '&.MuiInputLabel-root.Mui-focused': {
        color: `${zeplinColor.Primary}`,
      },
    },
  },
  selectBorder: {
    '&:after': {
      borderBottom: `1.5px solid ${zeplinColor.Primary} !important`,
    },
  },
}));

const AddBlockout: FC<editProps> = ({
  edit,
  blockoutDetail,
  setUpdatePayload,
  updateLoader,
  setEditBtn,
}) => {
  const classes = useStyles();
  const time = getTime();
  const search = useLocation().search;
  const navigate = useNavigate();
  const clinicId = new URLSearchParams(search).get('id');
  const prevLink = [
    { path: '/clinics', label: 'Clinics' },
    { path: `/clinics/detail/${clinicId}`, label: 'View Clinic' },
    { path: `/clinics/view/booking?id=${clinicId}`, label: 'Bookings & Blockouts' },
  ];
  const [blockoutTitle, setBlockoutTitle] = useState<string>(blockoutDetail?.title || '');
  const [selectBlockoutForOption, setBlockoutForOption] = React.useState<string>(
    blockoutDetail?.type || '',
  );
  const [isAllDay, setIsAllDay] = useState<boolean>(blockoutDetail?.allDay || false);
  const [startDate, setStartDate] = React.useState<Moment | null | string>(
    (blockoutDetail &&
      blockoutDetail.startDate &&
      moment(blockoutDetail.startDate, Constants.MOMENT_FORMATS.YYYY_MM_DD).format(
        Constants.MOMENT_FORMATS.DATE,
      )) ||
      null,
  );
  const [startTime, setStartTime] = React.useState<string>(
    blockoutDetail?.startTime
      ? moment(blockoutDetail?.startTime, [Constants.MOMENT_FORMATS.TIME]).format(Constants.MOMENT_FORMATS.HH_MM)
      : '',
  );
  const [endDate, setEndDate] = React.useState<Moment | null | string>(
    (blockoutDetail &&
      blockoutDetail.endDate &&
      moment(blockoutDetail.endDate, Constants.MOMENT_FORMATS.YYYY_MM_DD).format(
        Constants.MOMENT_FORMATS.DATE,
      )) ||
      null,
  );
  const [endTime, setEndTime] = React.useState<string>(
    blockoutDetail?.endTime
      ? blockoutDetail?.allDay
        ? '00:00'
        : moment(blockoutDetail?.endTime, [Constants.MOMENT_FORMATS.TIME]).format(Constants.MOMENT_FORMATS.HH_MM)
      : '',
  );
  const [listOperatories, setListOperatories] = useState<IClinicOperatories>();
  const blockoutOperatory: IClinicOperatories = {
    operatoryNumber: (blockoutDetail && blockoutDetail.operatoryNumber) || '',
    operatoryName: (blockoutDetail && blockoutDetail.operatoryName) || '',
    operatoryCode: '',
  };
  const [selectOperatory, setSelectOperatory] =
    React.useState<IClinicOperatories>(blockoutOperatory);
  const [searchOperatory, setSearchOperatory] = useState<string>('');
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [showSnackbar, setShowSnackbar] = useState<boolean>(false);
  const [showSnackbarMessage, setSnackbarMessage] = useState<string>('');
  const [enableCreateBtn, setEnableCreateBtn] = useState<boolean>(true);
  const [currentTime, setCurrentTime] = useState('');
  const [currentDate, setCurrentDate] = useState('');
  const [checkCurrentEndDate, setCurrentEndDateChecker] = useState(false);
  const [checkCurrentStartDate, setCurrentStartDateChecker] = useState(false);

  const handleBlockoutTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBlockoutTitle(event.target.value);
  };

  const handleBlockoutForOption = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBlockoutForOption(event.target.value as BlockOutType);
    event.target.value === BlockOutType.OPERATORY ? setSelectOperatory(blockoutOperatory) : '';
  };

  const handleIsAllDay = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsAllDay(() => event.target.checked);
  };
  const handleStartDateChange = (newValue: any) => {
    setStartDate(newValue);
    setCurrentStartDateChecker(
      currentDate === moment(newValue).format(Constants.MOMENT_FORMATS.YYYY_MM_DD) ? true : false,
    );
  };
  const handleStartTimeChange = (event: SelectChangeEvent<string>) => {
    setStartTime(moment(event.target.value, [Constants.MOMENT_FORMATS.TIME]).format(Constants.MOMENT_FORMATS.HH_MM));
  };
  const handleEndDateChange = (newValue: any) => {
    setEndDate(newValue);
    setCurrentEndDateChecker(
      currentDate === moment(newValue).format(Constants.MOMENT_FORMATS.YYYY_MM_DD) ? true : false,
    );
  };
  const handleEndTimeChange = (event: SelectChangeEvent<string>) => {
    setEndTime(moment(event.target.value, [Constants.MOMENT_FORMATS.TIME]).format(Constants.MOMENT_FORMATS.HH_MM));
  };
  const onSelectOperatory = (inputValue: IClinicOperatories) => {
    setSelectOperatory(inputValue);
  };

  const onSearchOperatory = (searchVal: string) => {
    setSearchOperatory(searchVal);
  };

  useEffect(() => {
    if (searchOperatory.trim().length > 2) {
      getOperatoriesForClinic();
    }
  }, [searchOperatory]);

  useEffect(() => {
    getOperatoriesForClinic();
  }, [clinicId]);

  useEffect(() => {
    getCurrentTime();
    validateBlockoutPayload();
    if (checkCurrentStartDate && startTime < currentTime) {
      setStartTime('');
    }
    if (checkCurrentEndDate && endTime < currentTime) {
      setEndTime('');
    }
    if (edit) {
      setUpdatePayload && setUpdatePayload(createBlockoutPayload);
    }
  }, [
    blockoutTitle,
    selectBlockoutForOption,
    isAllDay,
    startDate,
    endDate,
    startTime,
    endTime,
    selectOperatory,
  ]);

  const createBlockoutPayload: IAddBlockoutPayload = {
    title: blockoutTitle,
    type: selectBlockoutForOption,
    allDay: isAllDay,
    operatoryNumber:
      selectBlockoutForOption === BlockOutType.OPERATORY ? selectOperatory?.operatoryNumber : '',
    startDate: moment(startDate).format(Constants.MOMENT_FORMATS.YYYY_MM_DD),
    startTime: startTime && moment(startTime, [Constants.MOMENT_FORMATS.TIME]).format(Constants.MOMENT_FORMATS.HH_MM_SS),
    endDate: moment(endDate).format(Constants.MOMENT_FORMATS.YYYY_MM_DD),
    endTime:
      endTime && isAllDay
        ? '00:00'
        : moment(endTime, [Constants.MOMENT_FORMATS.TIME]).format(Constants.MOMENT_FORMATS.HH_MM_SS),
  };

  const getCurrentTime = () => {
    const date = new Date();
    setCurrentDate(moment(date).format(Constants.MOMENT_FORMATS.YYYY_MM_DD));
    const showTime = date.getHours() + ':' + date.getMinutes();
    setCurrentTime(moment(showTime, 'H:mm').format(Constants.MOMENT_FORMATS.HH_MM));
  };

  const getOperatoriesForClinic = async () => {
    if (clinicId) {
      try {
        const listOperatories: IClinicOperatoriesRO = await ClinicService.getOperatoriesByClinicId(
          clinicId,
        );
        setListOperatories(listOperatories?.data);
      } catch (err) {
        setShowLoader(false);
        setSnackbarMessage(
          typeof err === 'string' ? err : (err as any).response.data.error.message[0],
        );
        setShowSnackbar(true);
      }
    }
  };
  const validateBlockoutPayload = async () => {
    if (
      !isAllDay &&
      (startDate === null || startTime === '' || endTime === '' || endDate === null)
    ) {
      setEnableCreateBtn(true);
      setEditBtn && setEditBtn(true);
    } else if ((isAllDay && startDate === null) || blockoutTitle === '') {
      setEnableCreateBtn(true);
      setEditBtn && setEditBtn(true);
    } else if (
      selectBlockoutForOption === BlockOutType.OPERATORY &&
      selectOperatory?.operatoryNumber === undefined
    ) {
      setEnableCreateBtn(true);
      setEditBtn && setEditBtn(true);
    } else {
      setEnableCreateBtn(false);
      setEditBtn && setEditBtn(false);
    }
  };

  const createblockout = async () => {
    if (clinicId) {
      setShowLoader(true);
      const blockoutPayload: IAddBlockoutPayload = {
        title: blockoutTitle,
        type: selectBlockoutForOption,
        allDay: isAllDay,
        operatoryNumber: selectOperatory.operatoryNumber || '',
        startDate: moment(startDate).format(Constants.MOMENT_FORMATS.YYYY_MM_DD),
        startTime:
          startTime && moment(startTime, [Constants.MOMENT_FORMATS.TIME]).format(Constants.MOMENT_FORMATS.HH_MM_SS),
        endDate: moment(endDate).format(Constants.MOMENT_FORMATS.YYYY_MM_DD),
        endTime: endTime && moment(endTime, [Constants.MOMENT_FORMATS.TIME]).format(Constants.MOMENT_FORMATS.HH_MM_SS),
      };
      try {
        const createRes = await ClinicService.addClinicBlockout(clinicId, blockoutPayload);
        sessionStorage.setItem('addedBlockout', createRes?.data?.title);
        setShowLoader(false);
        navigate(prevLink[2].path);
      } catch (err) {
        setShowLoader(false);
        setShowSnackbar(true);
        setSnackbarMessage(
          typeof err === 'string' ? err : (err as any).response.data.error.message[0],
        );
      }
    }
  };

  return (
    <>
      {(showLoader || updateLoader) && <Loader />}
      <div className='blockoutAddContainer'>
        {edit ? (
          ''
        ) : (
          <div className='blockoutAddHeader'>
            <Toolbar style={{ color: 'primary', paddingRight: '0px', position: 'inherit' }}>
              <Typography variant='h5' component='div' className='blockoutTitle' color='inherit'>
                Add Blockout
                <br></br>
                <BreadCrumbs prevLink={prevLink} activeLink={'Add Blockout'} />
              </Typography>
              <div style={{ display: 'flex', gap: '16px' }}>
                <Button
                  variant='outlined'
                  style={{
                    width: '8em',
                    height: '34px',
                    backgroundColor: `${zeplinColor.Background97}`,
                    border: `1px solid ${zeplinColor.Background70}`,
                    color: `${zeplinColor.Background70}`,
                  }}
                  onClick={() => navigate(prevLink[2].path)}
                >
                  Cancel
                </Button>
                <Buttons
                  text='Add Blockout'
                  width='100%'
                  disable={enableCreateBtn}
                  onClick={createblockout}
                />
              </div>
            </Toolbar>
          </div>
        )}
        <Paper className='blockoutAddBox' sx={{ borderRadius: '16px', paddingBottom: '3rem' }}>
          <div className='blockoutAdd'>
            <section className='blockoutSection'>
              <section>
                <Typography variant='h6'>Blockout Title*</Typography>
                <Typography variant='body2'>Add title for the blockout</Typography>
              </section>
              <Field
                name='blockoutTitle'
                label='Title*'
                placeholder='Title'
                showIcon={false}
                style={{ width: '520px', marginLeft: '0px' }}
                value={blockoutTitle}
                onChange={handleBlockoutTitleChange}
              />
            </section>
            <section className='blockoutSection'>
              <section>
                <Typography variant='h6'>Blockout For*</Typography>
                <Typography variant='body2'>Choose the blockout option you require</Typography>
              </section>
              <FormControl>
                <RadioGroup
                  aria-labelledby='demo-controlled-radio-buttons-group'
                  name='controlled-radio-buttons-group'
                  value={selectBlockoutForOption}
                  onChange={handleBlockoutForOption}
                >
                  <FormControlLabel
                    value={BlockOutType.CLINIC}
                    control={
                      <Radio
                        sx={{
                          '&.Mui-checked': {
                            color: `${zeplinColor.Primary}`,
                          },
                        }}
                      />
                    }
                    label={<Typography variant='body1'>Clinic</Typography>}
                  />
                  <FormControlLabel
                    value={BlockOutType.OPERATORY}
                    control={
                      <Radio
                        sx={{
                          '&.Mui-checked': {
                            color: `${zeplinColor.Primary}`,
                          },
                        }}
                      />
                    }
                    label={<Typography variant='body1'>Operatory</Typography>}
                  />
                </RadioGroup>
              </FormControl>
            </section>
            {selectBlockoutForOption && selectBlockoutForOption === BlockOutType.OPERATORY && (
              <section className='blockoutSection'>
                <SearchDropdown
                  data-testid='dropdown'
                  searchList={listOperatories || [blockoutOperatory]}
                  width={'100%'}
                  label={'Select Operatory*'}
                  onSelectHandler={onSelectOperatory}
                  onSearchHandler={onSearchOperatory}
                  operatory={true}
                  multiple={false}
                  value={selectOperatory}
                  // fixed={selectOperatory ? true : false}
                />
              </section>
            )}
            <section className='blockoutSection'>
              <section>
                <Typography variant='h6'> Duration*</Typography>
                <Typography variant='body2'>
                  Choose start and end duration for the blockout
                </Typography>
              </section>
              <section style={{ display: 'flex', gap: 32, flexDirection: 'column' }}>
                <FormControlLabel
                  style={{ marginLeft: '-0.75em', flexDirection: 'row', width: 'fit-content' }}
                  labelPlacement='start'
                  control={
                    <Switch
                      color='primary'
                      onChange={(e) => handleIsAllDay(e)}
                      checked={isAllDay}
                    />
                  }
                  label={<Typography variant='body1'>All Day</Typography>}
                />
                <section style={{ display: 'flex', gap: 32, flexDirection: 'row' }}>
                  <Typography style={{ width: 32 }} variant='subtitle1'>
                    Start
                  </Typography>
                  <span style={{ marginTop: '-1.5em' }}>
                    <CustomDatePicker
                      value={startDate}
                      handleDateChange={handleStartDateChange}
                      label='Date (mm/dd/yyyy)'
                    />
                  </span>
                  {!isAllDay && (
                    <FormControl
                      variant='standard'
                      sx={{ m: 1, minWidth: 200, margin: 0, marginTop: '-1.5em' }}
                    >
                      <InputLabel className={classes.inputLabelRoot} id='open-time-picker-label'>
                        Time
                      </InputLabel>
                      <Select
                        className={classes.selectBorder}
                        labelId='open-time-picker-label'
                        id='open-time-picker'
                        value={formatTime(startTime)}
                        onChange={(event) => handleStartTimeChange(event)}
                        label='Time'
                        MenuProps={{
                          PaperProps: {
                            sx: {
                              maxHeight: 180,
                            },
                          },
                        }}
                      >
                        {time.map((i: any) => (
                          <MenuItem
                            key={i}
                            value={i}
                            disabled={checkCurrentStartDate && currentTime > i ? true : false}
                          >
                            {i}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                </section>
                {!isAllDay && (
                  <section style={{ display: 'flex', gap: 32, flexDirection: 'row' }}>
                    <Typography style={{ width: 32 }} variant='subtitle1'>
                      End
                    </Typography>
                    <span style={{ marginTop: '-1.5em' }}>
                      <CustomDatePicker
                        value={endDate}
                        handleDateChange={handleEndDateChange}
                        label='Date (mm/dd/yyyy)'
                      />
                    </span>
                    <FormControl
                      variant='standard'
                      sx={{ m: 1, minWidth: 200, margin: 0, marginTop: '-1.5em' }}
                    >
                      <InputLabel className={classes.inputLabelRoot} id='open-time-picker-label'>
                        Time
                      </InputLabel>
                      <Select
                        className={classes.selectBorder}
                        labelId='open-time-picker-label'
                        id='open-time-picker'
                        value={formatTime(endTime)}
                        onChange={(event) => handleEndTimeChange(event)}
                        label='Time'
                        MenuProps={{ PaperProps: { sx: { maxHeight: 180 } } }}
                      >
                        {time.map((i: any) => (
                          <MenuItem
                            key={i}
                            value={i}
                            disabled={checkCurrentStartDate && currentTime > i ? true : false}
                          >
                            {i}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </section>
                )}
              </section>
            </section>
          </div>
        </Paper>
        {showSnackbar && (
          <SnackBar message={showSnackbarMessage} show={showSnackbar} setShow={setShowSnackbar} />
        )}
      </div>
    </>
  );
};

export default AddBlockout;
