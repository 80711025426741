/*
 * File: Insurance.tsx
 * Project: mint-portal
 * File Created: Saturday, 17th September 2022 8:04:31 am
 * Author: Sowmiya Ramesh (sowmiya.ramesh@mutualmobile.com)
 * -----
 * Last Modified: Tuesday, 28th February 2023 7:34:27 pm
 * Modified By: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import _debounce from 'lodash/debounce';
import { Pagination, Paper } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { zeplinColor } from '../../../../../theme';
import {
  InsuranceDTO,
  InsuranceListAPIResponse,
  InsurancesByClinicIdRO,
  InsurancesDetails,
  InsurancesResponse,
  IViewClinicDetailsData,
} from '../../../../../model';
import { InsuranceService } from '../../../../../service/insurance.service';
import SearchDropdown from '../../../../shared/SearchDropdown/SearchDropdown';
import Loader from '../../../../shared/Loader/Loader';
import SnackBar from '../../../../shared/Snack-Bar/snackBar';
import EmptyState from '../../../../shared/EmptyState/EmptyState';
import { useLocation } from 'react-router-dom';
import { Constants } from '../../../../../utilities/constants';
import { Utilities } from '../../../../../utilities/utilities';
import CustomToolTip from '../../../../shared/CustomToolTip';
import InfoTextBox from '../../../../shared/InfoText/InfoText';

interface InsuranceListColumn {
  id: string;
  label: string;
  minWidth?: number;
  align?: 'right';
  format?: (value: number) => string;
}

type insuranceProps = {
  updatedClinicDetails: IViewClinicDetailsData | undefined;
  handleCreateClinicBtn: (value: boolean) => void;
};
const staffListColumns: InsuranceListColumn[] = [
  { id: 'insurance_name', label: 'Insurance Name', minWidth: 150 },
  { id: 'actions', label: 'Actions', minWidth: 150 },
];
const useStyles = makeStyles(() => ({
  ul: {
    '& .MuiPaginationItem-root': {
      color: zeplinColor.Background70,
      fontWeight: 'bold',
      '&.Mui-disabled': {
        background: zeplinColor.Background90,
      },
      '&.Mui-selected': {
        color: zeplinColor.Primary,
        border: `1px solid ${zeplinColor.Primary}`,
        background: zeplinColor.Background,
        fontWeight: 'bold',
      },
    },
  },
}));

const Insurance = ({ updatedClinicDetails, handleCreateClinicBtn }: insuranceProps) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [insuranceList, setInsuranceList] = useState<InsurancesResponse>({
    insurances: [],
    total: 0,
  });
  const [addedInsurances, setAddedInsurances] = useState<InsurancesDetails[]>([]);
  const [dataPage, setDataPage] = useState<number>(1);
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [showSnackbar, setShowSnackbar] = useState<boolean>(false);
  const [showSnackbarMessage, setShowSnackbarMessage] = useState<string>('');
  const [total, setTotal] = useState<number>(0);
  const route = useLocation();
  const [hide, setHide] = useState<boolean>(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onDataPageChange = (event: React.ChangeEvent<unknown>, page: number) => {
    getExistingInsurances(page);
  };

  const onSelectInsurance = (inputValue: any) => {
    setAddedInsurances(inputValue);
    setTotal(inputValue?.length);
    handleSave(
      'assign',
      inputValue[inputValue?.length - 1].id,
      inputValue[inputValue?.length - 1].insuranceName,
    );
    getInsurance();
  };
  const onSearchInsurance = async (searchVal: string) => {
    if (searchVal?.length > 2) {
      _debounce(getInsurance(searchVal) as any, 2000);
    }
  };

  useEffect(() => {
    if (updatedClinicDetails?.id) {
      getInsurance();
      getExistingInsurances(1);
    }
  }, [updatedClinicDetails?.id]);

  const getInsurance = async (searchString?: string) => {
    try {
      const limit = Constants.PAGINATION_OPTIONS.limit;
      const insuranceListQueryParams =
        searchString && searchString?.length > 2
          ? { searchString, notClinicId: updatedClinicDetails?.id }
          : {
            limit: limit,
            skip: 0,
            notClinicId: updatedClinicDetails?.id,
            isAllClinicAccess: false,
          };
      const response: InsuranceListAPIResponse = await InsuranceService.getInsuranceList(
        insuranceListQueryParams,
      );
      setInsuranceList(response?.data);
    } catch (error) {
      setShowSnackbar(true);
      setShowSnackbarMessage(
        typeof error === 'string' ? error : (error as any).response.data.error.message[0],
      );
    }
  };

  const getExistingInsurances = async (page: number) => {
    if (updatedClinicDetails && updatedClinicDetails.id) {
      setDataPage(page);
      const limit = Constants.PAGINATION_OPTIONS.limit;
      const queryParams = {
        limit: limit,
        skip: (page - 1) * limit,
        clinicId: updatedClinicDetails.id,
      };
      const response: InsurancesByClinicIdRO | '' =
        await InsuranceService.getInsurancesListByClinicId(queryParams);
      response && page === 1 && setTotal(response?.data?.total);
      response && setAddedInsurances(response?.data?.insurances);
    }
  };

  const handleSave = async (action: string, id: number, insuranceName: string) => {
    if (updatedClinicDetails?.id) {
      setShowLoader(true);
      try {
        const payLoad: InsuranceDTO = {
          clinicId: updatedClinicDetails?.id,
          action: action,
          insuranceId: id,
        };
        const res = await InsuranceService.saveInsurances(payLoad);
        if (res.data && !res.data.clinicId) {
          handleCreateClinicBtn(false);
        }
        getInsurance();
        getExistingInsurances(1);
        setShowLoader(false);
        if (action === 'assign') {
          setShowSnackbar(true);
          setShowSnackbarMessage(`${insuranceName} Saved`);
        }
        if (action === 'unassign') {
          setShowSnackbar(true);
          setShowSnackbarMessage(`${insuranceName} deleted`);
        }
      } catch (err) {
        setShowSnackbar(true);
        setShowSnackbarMessage(
          typeof err === 'string' ? err : (err as any).response.data.error.message[0],
        );
        setShowLoader(false);
      }
    }
  };
  const removeInsurance = (insuranceId: number, insurance: string) => {
    setTotal(total - 1);
    handleSave('unassign', insuranceId, insurance);
  };
  const handleClickOpen = () => {
    setOpen(true);
    setHide(true);
  };
  const handleClickClose = () => {
    setOpen(false);
    setHide(false);
  };

  return (
    <>
      {showLoader && <Loader margin={true} />}
      <div style={{ width: '100%' }}>
        {(!updatedClinicDetails?.id || !updatedClinicDetails?.openDentalConfig?.id) && (
          <section
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '712px',
            }}
          >
            <EmptyState
              path={'/'}
              text={Utilities.getTextSubTextForClinicEmptyState(updatedClinicDetails)?.text}
              subText={Utilities.getTextSubTextForClinicEmptyState(updatedClinicDetails)?.subText}
              buttonText={''}
              showAddButton={false}
              image={false}
            />
          </section>
        )}
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '2rem',
            margin: '20px 32px',
            width: '100%',
          }}
        >
          <Box>
            <Box>
              <Typography variant='h5'>Insurances</Typography>
            </Box>
          </Box>
          <Box>
            <InfoTextBox
              content={Constants.INFO_TEXTS.NEW_INSURANCE_IN_CLINIC}
              width={'91%'}
              show={true}
            />
          </Box>
          <Paper className='' sx={{ borderRadius: '16px', padding: '0px 11px', width: '91%' }}>
            <TableContainer>
              <Table aria-label='caption table'>
                <TableHead>
                  <TableRow>
                    {route?.pathname?.split('/')[2] !== 'view' ? (
                      staffListColumns.map((column) => (
                        <TableCell key={column.id} align={column.align}>
                          <Typography
                            style={{ color: zeplinColor.Background50 }}
                            variant='subtitle1'
                          >
                            {column.label}
                          </Typography>
                        </TableCell>
                      ))
                    ) : (
                      <TableCell>
                        <Typography style={{ color: zeplinColor.Background50 }} variant='subtitle1'>
                          Insurance Name
                        </Typography>
                      </TableCell>
                    )}
                  </TableRow>
                </TableHead>
                <Box style={{ margin: '24px' }}>
                  {route?.pathname?.split('/')[2] === 'view' ? (
                    <></>
                  ) : (
                    !hide && (
                      <Typography
                        variant='subtitle1'
                        className='buttonText'
                        color='primary'
                        onClick={handleClickOpen}
                        style={{ cursor: 'pointer' }}
                      >
                        ASSIGN INSURANCE
                      </Typography>
                    )
                  )}

                  {open && hide && insuranceList && insuranceList?.insurances && (
                    <Box
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '16px',
                        alignItems: 'flex-end',
                      }}
                    >
                      <SearchDropdown
                        searchList={insuranceList?.insurances}
                        label={'Search Insurance Name'}
                        onSelectHandler={onSelectInsurance}
                        onSearchHandler={onSearchInsurance}
                        insurances={true}
                        value={addedInsurances}
                        width='400px'
                      />
                      <Typography
                        className='buttonText'
                        color='primary'
                        onClick={handleClickClose}
                        style={{ cursor: 'pointer' }}
                      >
                        CANCEL
                      </Typography>
                    </Box>
                  )}
                </Box>
                <TableBody>
                  {addedInsurances?.length
                    ? addedInsurances?.map((insuranceObj) => {
                      return (
                        <TableRow key={insuranceObj.id}>
                          <TableCell>
                            <CustomToolTip title={insuranceObj.insuranceName} width='25rem' />
                          </TableCell>

                          <TableCell>
                            {route?.pathname?.split('/')[2] === 'view' ? (
                              <></>
                            ) : (
                              <Typography
                                style={{ color: zeplinColor.Error, cursor: 'pointer' }}
                                onClick={() => {
                                  removeInsurance(insuranceObj.id, insuranceObj.insuranceName);
                                }}
                              >
                                  Delete
                              </Typography>
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })
                    : ''}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
          {addedInsurances?.length ? (
            <Pagination
              color='primary'
              count={
                total <= Constants.PAGINATION_OPTIONS.limit
                  ? 1
                  : Math.ceil(total / Constants.PAGINATION_OPTIONS.limit)
              }
              onChange={onDataPageChange}
              page={dataPage}
              variant='outlined'
              shape='rounded'
              classes={{ outlined: classes.ul }}
            />
          ) : (
            ''
          )}
          {showSnackbar && (
            <SnackBar message={showSnackbarMessage} show={showSnackbar} setShow={setShowSnackbar} />
          )}
        </div>
      </div>
    </>
  );
};

export default Insurance;
