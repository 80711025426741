/*
 * File: ClinicServices.tsx
 * Project: mint-portal
 * File Created: Tuesday, 27th September 2022 5:35:50 pm
 * Author: Sowmiya Ramesh (sowmiya.ramesh@mutualmobile.com)
 * -----
 * Last Modified: Thursday, 16th February 2023 11:26:19 am
 * Modified By: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */
import React, { useEffect, useState } from 'react';
import EmptyState from '../../../../shared/EmptyState/EmptyState';
import { IOpenDentalSyncStatusRO, IViewClinicDetailsData } from '../../../../../model';
import { Box, Typography } from '@material-ui/core';
import InfoTextBox from '../../../../shared/InfoText/InfoText';
import SearchDropdown from '../../../../shared/SearchDropdown/SearchDropdown';
import './ClinicServices.css';
import { zeplinColor } from '../../../../../theme';
import {
  IServiceList,
  IServiceListAPIResponse,
  IServiceListByClinic,
} from '../../../../../model/service.model';
import { ServiceService } from '../../../../../service/service.service';
import { ClinicService } from '../../../../../service/clinic.service';
import SnackBar from '../../../../shared/Snack-Bar/snackBar';
import Loader from '../../../../shared/Loader/Loader';
import _debounce from 'lodash/debounce';
import ServiceOption from '../../../../shared/ServiceOptions/ServiceOption';
import { Pagination } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import { useLocation } from 'react-router-dom';
import { Constants } from '../../../../../utilities/constants';
import { Utilities } from '../../../../../utilities/utilities';
import Buttons from '../../../../shared/Buttons';

type servicesProps = {
  updatedClinicDetails: IViewClinicDetailsData | undefined;
  handleCreateClinicBtn: (value: boolean) => void;
};
const useStyles = makeStyles(() => ({
  ul: {
    '& .MuiPaginationItem-root': {
      color: zeplinColor.Background70,
      fontWeight: 'bold',
      '&.Mui-disabled': {
        background: zeplinColor.Background90,
      },
      '&.Mui-selected': {
        color: zeplinColor.Primary,
        border: `1px solid ${zeplinColor.Primary}`,
        background: zeplinColor.Background,
        fontWeight: 'bold',
      },
    },
  },
  tableRow: {
    '&:hover': {
      backgroundColor: `${zeplinColor.Primary50} !important`,
      cursor: 'pointer',
    },
  },
}));
const ClinicServices = ({ updatedClinicDetails, handleCreateClinicBtn }: servicesProps) => {
  const [serviceList, setServiceList] = useState<IServiceList[]>([]);
  const [addedServiceList, setAddedServiceList] = useState<IServiceList[]>([]);
  const [newService, setNewService] = useState<IServiceList[]>([]);
  const [showSnackbar, setShowSnackbar] = useState<boolean>(false);
  const [showSnackbarMessage, setShowSnackbarMessage] = useState<string>('');
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [total, setTotal] = useState<number>(0);
  const [visited, setVisited] = useState<number[]>([]);
  const [openDentalSyncStatus, setOpenDentalSyncStatus] = useState<boolean>(false);
  const classes = useStyles();
  const route = useLocation();
  const getService = async (searchString?: string) => {
    try {
      const serviceListQueryParams =
        searchString && searchString?.length > 2
          ? { searchString, notClinicId: updatedClinicDetails?.id }
          : { limit: Constants.LIMIT, skip: 0, notClinicId: updatedClinicDetails?.id };
      const response: IServiceListAPIResponse = await ServiceService.getServiceList(
        serviceListQueryParams,
      );
      setServiceList(response?.data?.services);
    } catch (err) {
      setShowSnackbar(true);
      setShowSnackbarMessage(
        typeof err === 'string' ? err : (err as any).response.data.error.message[0],
      );
    }
  };

  const getOpenDentalSyncStatus = async () => {
    setShowLoader(true);
    try {
      let openDentalSyncStatusRO: IOpenDentalSyncStatusRO = {
        openDentalSync: false, // TODO: not checking this key because we have no server agent ready yet.
        operatories: false,
        procedureCodes: false,
        providers: false,
      };
      if (updatedClinicDetails?.id) {
        openDentalSyncStatusRO = await ClinicService.getOpenDentalSyncStatus(
          updatedClinicDetails.id,
        );
      }
      const isOpenDentalSyncDone = !!openDentalSyncStatusRO?.procedureCodes;
      setOpenDentalSyncStatus(isOpenDentalSyncDone);
      if (isOpenDentalSyncDone) {
        // it is required, because we need to fetch latest getService and existing services after sync done
        getService();
        existingService(1);
      }
    } catch (error) {
      setShowSnackbar(true);
      setShowSnackbarMessage(
        typeof error === 'string' ? error : (error as any)?.response?.data?.error.message[0],
      );
    } finally {
      setShowLoader(false);
    }
  };

  useEffect(() => {
    if (!updatedClinicDetails?.isPartialData) {
      if (addedServiceList?.length) {
        handleCreateClinicBtn(false);
      } else {
        handleCreateClinicBtn(true);
      }
    }
  }, [addedServiceList?.length]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (
      updatedClinicDetails?.id &&
      updatedClinicDetails?.isDraft &&
      updatedClinicDetails?.openDentalConfig?.id
    ) {
      // GET OPEN DENTAL STATUS ONLY IF CLINIC IS IN DRAFT AND OPEN DENTAL CONFIG SET
      getOpenDentalSyncStatus();
    }
    if (updatedClinicDetails?.isDraft === false) {
      // if clinic is not in draft state, then no need to check open dental sync status
      setOpenDentalSyncStatus(true);
      getService();
      existingService(1);
    }
    if (updatedClinicDetails?.id && openDentalSyncStatus) {
      // if open dental not in sync, then no need to call service apis
      getService();
      existingService(1);
    }
  }, [updatedClinicDetails?.id, openDentalSyncStatus]);
  const existingService = async (page: number) => {
    setShowLoader(true);
    try {
      setCurrentPage(page ? page : 1);
      const serviceListQueryParams = {
        limit: Constants.LIMIT,
        skip: page ? (page - 1) * Constants.LIMIT : 0,
        clinicId: updatedClinicDetails?.id,
      };

      const response: IServiceListByClinic | '' = await ServiceService.getServiceByClinic(
        serviceListQueryParams,
      );
      response && setTotal(response?.data?.total);

      response && setAddedServiceList(response?.data?.services);
      // added due to MINTD-1592
      setTimeout(() => {
        setShowLoader(false);
      }, 4000);
    } catch (err) {
      setShowLoader(false);
      setShowSnackbar(true);
      setShowSnackbarMessage(
        typeof err === 'string' ? err : (err as any)?.response?.data?.error.message[0],
      );
    }
  };
  const onDataPageChange = (event: any, page: number) => {
    existingService(page);
    setVisited([...visited, page]);
  };

  const onSelectClinics = async (inputValue: any) => {
    route?.pathname?.split('/')[2] === 'add' &&
      setNewService([...newService, inputValue[inputValue?.length - 1]]);
    const existing = addedServiceList.filter(
      (d: IServiceList) => !inputValue.filter((ref: IServiceList) => ref.id === d.id)?.length,
    );
    existing?.length && remove(existing[0].id, existing[0].name);
    // if (route?.pathname?.split('/')[2] === 'edit') {
    try {
      updatedClinicDetails &&
        (await ClinicService.editClinicService({
          clinicId: updatedClinicDetails?.id,
          action: existing?.length ? 'unassign' : 'assign',
          serviceId: inputValue[inputValue?.length - 1].id,
        }));
      setAddedServiceList(inputValue);
      existingService(currentPage);
      getService();
      setShowSnackbar(true);
      setShowSnackbarMessage(`${inputValue[inputValue?.length - 1].name} is assigned`);
    } catch (err) {
      setShowSnackbar(true);
      setShowSnackbarMessage(
        typeof err === 'string' ? err : (err as any).response.data.error.message[0],
      );
    }
    // }
  };
  const onsearchServices = async (searchVal: string) => {
    if (searchVal?.length > 2) {
      _debounce(getService(searchVal) as any, 2000);
    } else {
      _debounce(getService('') as any, 2000);
    }
  };

  const remove = async (id: number, name: string) => {
    setShowLoader(true);
    const val = addedServiceList.filter((val) => id !== val?.id);
    setAddedServiceList(val);
    const valWithoutApi = newService.filter((val) => id !== val?.id);
    setNewService(valWithoutApi);
    updatedClinicDetails &&
      (await ClinicService.editClinicService({
        clinicId: updatedClinicDetails?.id,
        action: 'unassign',
        serviceId: id.toString(),
      }));
    setShowSnackbar(true);
    setShowSnackbarMessage(`${name} unassigned`);
    if (currentPage !== 1) {
      Math.ceil(total / Constants.LIMIT) > currentPage
        ? setCurrentPage(currentPage)
        : setCurrentPage(currentPage - 1);
      addedServiceList?.length > 1
        ? existingService(currentPage)
        : existingService(currentPage - 1);
    } else {
      setCurrentPage(1);
      existingService(1);
    }
  };

  return (
    <div>
      {!updatedClinicDetails?.id ||
      !updatedClinicDetails?.openDentalConfig?.id ||
      !openDentalSyncStatus ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: 24,
              justifyContent: 'center',
              alignItems: 'center',
              height: '712px',
            }}
          >
            <EmptyState
              path={'/'}
              text={
                Utilities.getTextSubTextForClinicEmptyState(
                  updatedClinicDetails,
                  true,
                  openDentalSyncStatus,
                )?.text
              }
              subText={
                Utilities.getTextSubTextForClinicEmptyState(
                  updatedClinicDetails,
                  true,
                  openDentalSyncStatus,
                )?.subText
              }
              buttonText={''}
              showAddButton={false}
              image={false}
            />
            {updatedClinicDetails?.openDentalConfig?.id && !openDentalSyncStatus && (
              <div style={{ position: 'absolute', top: '48.5rem' }}>
                <Buttons
                  text='refresh status'
                  width='170px'
                  disable={false}
                  onClick={getOpenDentalSyncStatus}
                />
              </div>
            )}
          </div>
        ) : (
          <Box>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '2rem',
                margin: '20px 32px',
                width: '100%',
              }}
            >
              {showLoader && <Loader margin={false} />}
              <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Box>
                  <Typography variant='h5'>Services</Typography>
                </Box>
              </Box>
              {route?.pathname?.split('/')[2] === 'view' ? (
                <></>
              ) : (
                <>
                  <Box>
                    <InfoTextBox
                      content={Constants.INFO_TEXTS.NEW_SERVICE_IN_CLINIC}
                      width={'91%'}
                      show={true}
                    />
                  </Box>
                  <Box>
                    <InfoTextBox
                      content={Constants.INFO_TEXTS.ASSIGN_OPERATORY}
                      width={'91%'}
                      show={true}
                    />
                  </Box>
                  <Box>
                    <SearchDropdown
                      searchList={serviceList}
                      label={'Search Service Name'}
                      onSelectHandler={onSelectClinics}
                      onSearchHandler={onsearchServices}
                      value={addedServiceList}
                      width='91%'
                      service
                    />
                  </Box>
                </>
              )}
              {addedServiceList?.length ? (
                addedServiceList?.map((d: IServiceList, ind) => {
                  return ind < 5 ? (
                    <Box
                      style={{
                        backgroundColor: zeplinColor.Background97,
                        // border: `1px solid ${zeplinColor.Background90}`,
                        width: '91%',
                      }}
                      className='selected-card'
                      key={ind}
                    >
                      <ServiceOption
                        valprop={d}
                        length={total}
                        remove={remove}
                        view={route?.pathname?.split('/')[2] === 'view'}
                      />
                    </Box>
                  ) : (
                    ''
                  );
                })
              ) : !showLoader ? (
                <EmptyState
                  path={'/clinics/edit'}
                  text={'No Service to show'}
                  subText={''}
                  buttonText={''}
                  showAddButton={false}
                  image={false}
                />
              ) : (
                ''
              )}
              <Pagination
                count={total <= Constants.LIMIT ? 1 : Math.ceil(total / Constants.LIMIT)}
                onChange={onDataPageChange}
                page={currentPage}
                variant='outlined'
                shape='rounded'
                classes={{ outlined: classes.ul }}
              />
            </div>
            {showSnackbar && (
              <SnackBar message={showSnackbarMessage} show={showSnackbar} setShow={setShowSnackbar} />
            )}
          </Box>
        )}
    </div>
  );
};
export default ClinicServices;
