/*
 * File: reward.model.ts
 * Project: mint-portal
 * File Created: Thursday, 13th October 2022 11:27:43 pm
 * Author: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Last Modified: Tuesday, 2nd April 2024 8:09:59 pm
 * Modified By: Jackson Thounaojam (jackson.thounaojam@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */

import { IFileDetails } from '.';
import { IQueryParam } from './manage.model';

export interface IRewardQueryParam extends IQueryParam {
  searchString?: string;
  rewardType?: string[];
  type?: string[];
  patientId?: string;
  hasInventoryStock?: boolean;
}
export interface FilterArray {
  id: number;
  label: string;
  value: boolean;
}

export interface IListRewardRO {
  message: string;
  data: IListRewardPaginationResponse;
}
export interface IListRewardPaginationResponse {
  total: number;
  rewards: IListRewardResponse[];
}

export interface IListRewardResponse {
  id: number;
  rewardType: string;
  pointsValue: number;
  inventoryCount: number;
  name: string;
}

export enum RewardType {
  Gift = 'gift',
  PointsCredit = 'points_credit',
  PointsDeducted = 'points_deducted',
  ManualCredit = 'manual_credit',
  ReferTo = 'refer_to',
  ReferBy = 'refer_by',
  AutoAssignedPoints = 'Auto-assigned Points',
}

export enum AutoRewardsBased {
  AppointmentBased = 'Appointment Based',
  MobileAppUseBased = 'Mobile App Use Based',
  FamilyAndFriends = 'Family and Friends',
  Promotional = 'Promotional',
}

export interface IListRewardRedeemHistoryRO {
  message: string;
  data: IListRedeemRewardHistoryPaginationResponse;
}
export interface IListRedeemRewardHistoryPaginationResponse {
  total: number;
  rewardRedeemHistory: IListRewardRedeemHistoryResponse[];
}
export interface IListRewardRedeemHistoryResponse {
  createdAt: string;
  id: number;
  type: RewardType;
  rewardName: string;
  patient: IDetailPatientObject;
  staff: IDetailStaffObject;
}

export interface IDetailRewardRedeemResponse extends IListRewardRedeemHistoryResponse {
  pointsValue: number;
}

export interface IDetailRedeemRewardHistoryRO {
  data: IDetailRewardRedeemResponse;
  messge: string;
}

export interface IDetailRewardResponse {
  id: number;
  rewardType: RewardType;
  pointsValue: number;
  name: string;
  giftReward: IGiftReward;
  pointsCreditReward: IPointCreditReward;
}

export interface IDetailRewardRO {
  data: IDetailRewardResponse;
  messge: string;
}

export interface IAddPointCreditRewardPayload {
  name: string;
  pointsValue: number;
  reason: string;
}

export interface IAddGiftRewardPayload {
  name: string;
  pointsValue: number;
  description: string;
  inventoryCount: number;
  categoryId: number;
  imagesS3Keys?: string[];
}

export interface IRewardCategoryRO {
  data: IRewardCategoryList;
  message: string;
}

export interface IRewardCategoryList {
  categories: ICategoryDetail[];
  total: number;
}

export interface ICategoryDetail {
  id: string;
  name: string;
}

export interface IRedeemPayload {
  patientId: string;
  rewardType: string;
  rewardId: number;
}

export interface IDeductPayload {
  patientId: string;
  points: number;
}

export interface IListAutoAssignRewardsRO {
  data: IListAutoAssignRewardsResponse[];
  message: string;
}

export interface IListAutoAssignRewardsResponse {
  createdAt: string;
  updatedAt: string;
  id: number;
  name: string;
  primaryPatientPointsValue: number;
  familyPatientPointsValue: number;
  status: boolean;
  canConfigured: boolean;
}

export interface IListElectiveProceduresRO {
  data: IListElectiveProcedureResponse;
  message: string;
}

export interface IListElectiveProcedureResponse {
  electiveProcedures: IElectiveProcedureAutoRewards[];
  total: number;
}
export interface IElectiveProcedureAutoRewards {
  createdAt: string;
  updatedAt: string;
  id: number;
  name: string;
  primaryPatientPointsValue: number;
  familyPatientPointsValue: number;
  status: boolean;
  canConfigured: boolean;
  procedureCodes: string[];
}

interface IGiftReward {
  description: string;
  inventoryCount: number;
  categoryName: string;
  images: IFileDetails[];
  categoryId: number;
}
interface IPointCreditReward {
  id: string;
  reason: string;
}

interface IDetailStaffObject {
  id: string;
  firstName: string;
  lastName: string;
}

interface IDetailPatientObject extends IDetailStaffObject {
  code: string;
}

export interface IAutoRewardResponse {
  createdAt: string;
  updatedAt: string;
  id: number;
  name: string;
  description: string;
  primaryPatientPointsValue: number;
  familyPatientPointsValue: number;
  status: boolean;
  canConfigured: boolean;
  pointsValue: number;
  procedureCodes: string[];
}

export interface IAutoRewardRO {
  data: IAutoRewardResponse;
  messge: string;
}

export interface IElectiveProcedurePayload {
  primaryPatientPointsValue?: number;
  familyPatientPointsValue?: number;
  procedureCodes: string[];
  name: string;
  description: string;
}
export interface IElectiveProcedureData {
  id: number;
  name: string;
  description: string;
  primaryPatientPointsValue: number;
  familyPatientPointsValue: number;
  status: boolean;
  procedureCodes: string[];
}

export interface IElectiveProcedure {
  data: IElectiveProcedureData;
  message: string;
}

export interface IAutoAssignPoints {
  isFamilyMember: boolean;
  points: number;
}
