/* eslint-disable indent */
/*
 * File: LegalDocuments.tsx
 * Project: mint-portal
 * File Created: Thursday, 13th October 2022 1:44:09 pm
 * Author: Sowmiya Ramesh (sowmiya.ramesh@mutualmobile.com)
 * -----
 * Last Modified: Monday, 1st April 2024 7:54:44 pm
 * Modified By: Jackson Thounaojam (jackson.thounaojam@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */

import React, { useEffect, useState } from 'react';
import BreadCrumbs from '../../../shared/BreadCrumbs/BreadCrumbs';
import InfoTextBox from '../../../shared/InfoText/InfoText';
import CustomTextField from '../../../shared/CustomTextField';
import { validateEmail } from '../../../../utilities/validate';
import {
  // Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { Paper } from '@mui/material';
import zeplinColor from '../../../../theme/zeplinColor';
import './LegalDocuments.css';
import { Constants } from '../../../../utilities/constants';
import moment from 'moment';
import { ManageService } from '../../../../service/manage.service';
import {
  IDSOLegalDocumentListResponse,
  IDSOLegalDocumentRO,
  LegalDocumentType,
} from '../../../../model';
import Loader from '../../../shared/Loader/Loader';
import SnackBar from '../../../shared/Snack-Bar/snackBar';
// import { FileService } from '../../../../service/file.service';
// import CustomToolTip from '../../../shared/CustomToolTip';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import EmptyState from '../../../shared/EmptyState/EmptyState';
import Buttons from '../../../shared/Buttons';
// import { FileService } from '../../../../service/file.service';

interface DocumentListColumn {
  id: 'document' | 'updated_on' | 'updated_by' | 'update' | 'link_url';
  label: string;
  minWidth?: number;
  align?: 'left';
  format?: (value: number) => string;
}
type inputList = { [key: number]: string };
const documentsListColumns: DocumentListColumn[] = [
  { id: 'document', label: 'Document', minWidth: 300 },
  { id: 'updated_on', label: 'Last Updated on', minWidth: 150 },
  { id: 'updated_by', label: 'Updated By', minWidth: 150 },
  // { id: 'update', label: '', minWidth: 150 },
  { id: 'link_url', label: 'Link URL' },
];

const LegalDocuments = () => {
  const prevLink = [{ path: '/manage', label: 'Manage' }];
  const [legalDocument, setLegalDocument] = React.useState<IDSOLegalDocumentListResponse[]>();
  // const [termsPdf, setTermsPdf] = React.useState<IDSOLegalDocumentListResponse>();
  // const [privacyPdf, setPrivacyPdf] = React.useState<IDSOLegalDocumentListResponse>();
  // const [rewardsPdf, setRewardsPdf] = React.useState<IDSOLegalDocumentListResponse>();
  const [isForbiddenAccess, setForbiddenAccess] = useState(false);

  const [showLoader, setShowLoader] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [showSnackBarMsg, setSnackBarMsg] = useState('');
  const [linkUrl, setLinkUrl] = useState<inputList>({ 0: '' });
  const [currentRow, setCurrentRow] = useState<number>();
  const [emailvalid, setEmailValid] = useState(false);

  useEffect(() => {
    setShowLoader(true);
    getDsoLegalDocuments();
  }, []);

  const getDsoLegalDocuments = async () => {
    try {
      const response: IDSOLegalDocumentRO = await ManageService.getDsoLegalDocument();
      setLegalDocument(response.data);
      // response.data.map(
      //   (termsFile: IDSOLegalDocumentListResponse) =>
      //     termsFile.type === LegalDocumentType.TERMS_AND_CONDITIONS && setTermsPdf(termsFile),
      // );
      // response.data.map(
      //   (privacyFile: IDSOLegalDocumentListResponse) =>
      //     privacyFile.type === LegalDocumentType.PRIVACY_POLICY && setPrivacyPdf(privacyFile),
      // );
      // response.data.map(
      //   (rewardsFile: IDSOLegalDocumentListResponse) =>
      //     rewardsFile.type === LegalDocumentType.REWARD_POLICY && setRewardsPdf(rewardsFile),
      // );

      setShowLoader(false);
    } catch (err) {
      setShowLoader(false);
      if ((err as any)?.response?.status === Constants.API_STATUS_CODE.FORBIDDEN) {
        setForbiddenAccess(true);
      }
      setShowSnackbar(true);
      setSnackBarMsg(typeof err === 'string' ? err : (err as any).response.data.error.message[0]);
    }
  };
  // const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>, type: string) => {
  //   const file = e.target.files;
  //   if (file?.length && file[0].size <= 5048576) {
  //     if (type === LegalDocumentType.TERMS_AND_CONDITIONS) {
  //       updateLegalDocument(1, file[0]);
  //     } else if (type === LegalDocumentType.PRIVACY_POLICY) {
  //       updateLegalDocument(2, file[0]);
  //     } else if (type === LegalDocumentType.REWARD_POLICY) {
  //       updateLegalDocument(3, file[0]);
  //     }
  //   } else {
  //     setShowSnackbar(true);
  //     setSnackBarMsg(
  //       `Document exceeds maximum size of 5 MB. Please try uploading a different one.`,
  //     );
  //   }
  // };

  const updateLegalDocument = async () => {
    setShowLoader(true);
    try {
      if (currentRow) {
        // const uploadRes = await FileService.getSignedUrlToUploadFile(
        //   currentRow === 1
        //     ? Constants.FILE_UPLOAD_CATEGORY.TERMS_AND_CONDITIONS
        //     : currentRow === 2
        //     ? Constants.FILE_UPLOAD_CATEGORY.PRIVACY_POLICY
        //     : Constants.FILE_UPLOAD_CATEGORY.REWARD_POLICY,
        //   linkUrl[currentRow],
        //   { 'Content-Type': Constants.HEADERS_CONTENT_TYPE.pdfType },
        // );
        // const s3Key = uploadRes?.signedUrl?.data?.data?.s3Key;
        const selectedPdf = legalDocument?.filter((val) => val?.id === currentRow)[0].type;
        const uploadPayload = {
          name: '',
          // s3Key: s3Key,
          type:
            selectedPdf === LegalDocumentType.TERMS_AND_CONDITIONS
              ? LegalDocumentType.TERMS_AND_CONDITIONS
              : selectedPdf === LegalDocumentType.PRIVACY_POLICY
              ? LegalDocumentType.PRIVACY_POLICY
              : LegalDocumentType.REWARD_POLICY,
          url: linkUrl[currentRow],
        };
        const res = await ManageService.updateDsoLegalDocument(uploadPayload);
        setLegalDocument(res.data);
        getDsoLegalDocuments();
        setShowSnackbar(true);
        setSnackBarMsg(`${linkUrl[currentRow]} is uploaded`);
        setShowLoader(false);
        setLinkUrl({ 0: '' });
      }
    } catch (err) {
      setShowLoader(false);
      setShowSnackbar(true);
      setSnackBarMsg(typeof err === 'string' ? err : (err as any).response.data.error.message[0]);
    }
  };

  const handleLinkUrl = (
    input: { target: { value: React.SetStateAction<string> } },
    id: number,
  ) => {
    setCurrentRow(id);
    setLinkUrl({ ...linkUrl, [id]: input.target.value.toString() });
    emailCheck(input.target.value.toString());
  };
  const saveLink = () => {
    updateLegalDocument();
    setCurrentRow(undefined);
  };
  console.log(linkUrl);
  const emailCheck = (value: string) => {
    const isValid = validateEmail(value);
    setEmailValid(isValid);
  };
  return (
    <>
      {isForbiddenAccess && (
        <EmptyState
          path={``}
          text={'Sorry, you don’t have access to this section'}
          subText={''}
          buttonText={''}
          showAddButton={false}
          image={true}
          removeImage={false}
          forbiddenAccess={true}
        />
      )}
      {showLoader && <Loader />}
      {!isForbiddenAccess && (
        <div className='documentsContainer'>
          <div className='documentsHeader'>
            <Toolbar style={{ color: 'primary', paddingRight: '0px', position: 'inherit' }}>
              <Typography variant='h5' component='div' className='documentsTitle' color='inherit'>
                Legal Documents
                <br></br>
                <BreadCrumbs prevLink={prevLink} activeLink={'Legal Documents'} />
              </Typography>
            </Toolbar>
            <div style={{ margin: '20px 0px 0px 20px' }}>
              <InfoTextBox
                content={Constants.INFO_TEXTS.LEGAL_DOCUMENT_WARNING}
                show={true}
                width={'76.5vw'}
              />
            </div>
            <Paper
              className='docContainer'
              sx={{ borderRadius: '16px', border: `1px solid ${zeplinColor.Background90}` }}
              elevation={0}
            >
              <TableContainer>
                <Table aria-label='caption table'>
                  <TableHead>
                    <TableRow>
                      {documentsListColumns.map((column) => (
                        <TableCell key={column.id} align={column.align}>
                          <Typography
                            style={{ color: zeplinColor.Background50 }}
                            variant='subtitle1'
                          >
                            {column.label}
                          </Typography>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {legalDocument &&
                      legalDocument.length &&
                      legalDocument?.map((obj: IDSOLegalDocumentListResponse) => (
                        <TableRow key={obj.id} style={{ height: '120px' }}>
                          <TableCell>
                            <Typography variant='h6'>
                              {obj.type === LegalDocumentType.TERMS_AND_CONDITIONS
                                ? 'Terms Of Service'
                                : obj.type === LegalDocumentType.PRIVACY_POLICY
                                ? 'Privacy Policy'
                                : 'Reward Policy'}
                            </Typography>
                            {/* <Link
                              href={obj.documentDetails?.location}
                              style={{ textDecoration: 'none' }}
                              target='_blank'
                            >
                              <CustomToolTip
                                title={
                                  obj.name
                                    ? obj.name
                                    : obj.id === 1
                                    ? termsPdf?.name
                                    : obj.id === 2
                                    ? privacyPdf?.name
                                    : obj.id === 3
                                    ? rewardsPdf?.name
                                    : ''
                                }
                                width='250px'
                                variant='subtitle1'
                              />
                            </Link> */}
                          </TableCell>
                          <TableCell>
                            {obj.updatedAt ? (
                              <Typography>
                                {moment(obj?.updatedAt).format(
                                  Constants.MOMENT_FORMATS.DATE_FORMAT,
                                )}
                              </Typography>
                            ) : (
                              <Typography>-</Typography>
                            )}
                          </TableCell>
                          <TableCell>
                            <Typography className='userName'>
                              {obj?.staff ? (
                                <img
                                  className='avatar'
                                  src={
                                    obj.staff?.profileImageDetails?.location ||
                                    Constants.FALL_BACK_IMAGE
                                  }
                                  onError={(e: any) => (e.target.src = Constants.FALL_BACK_IMAGE)}
                                />
                              ) : (
                                obj.documentDetails &&
                                obj.staff === null && (
                                  <AccountCircleIcon
                                    color='disabled'
                                    style={{ fontSize: '1.85rem', marginTop: -1 }}
                                  ></AccountCircleIcon>
                                )
                              )}
                              {obj?.staff
                                ? obj.staff?.firstName + ' ' + obj.staff?.lastName
                                : obj.documentDetails && !obj.staff
                                ? 'Deleted User'
                                : !obj?.documentDetails && !obj?.staff && '-'}
                            </Typography>
                          </TableCell>
                          <TableCell className='file-upload'>
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                            >
                              <CustomTextField
                                name='Link URL'
                                label='Link URL'
                                showIcon={false}
                                error={emailvalid}
                                value={linkUrl[obj.id] ? linkUrl[obj.id] : obj.url}
                                onChange={(e) => handleLinkUrl(e, obj.id)}
                                width={'100%'}
                              />{' '}
                              <Buttons
                                text='Save'
                                width='20%'
                                disable={currentRow === obj.id ? false : true}
                                onClick={saveLink}
                              />
                            </div>
                            {/* <div
                              className='file-inputs'
                              style={{ cursor: showLoader ? 'none' : 'pointer' }}
                            >
                              <input
                                type='file'
                                accept='.pdf'
                                multiple={false}
                                onChange={(e) => handleFileChange(e, obj.type)}
                                disabled={showLoader ? true : false}
                                style={{ cursor: showLoader ? 'none' : 'pointer' }}
                                title=''
                              />
                              <button
                                disabled={showLoader ? true : false}
                                style={{
                                  backgroundColor: showLoader
                                    ? zeplinColor.Background90
                                    : zeplinColor.Primary,
                                  color: zeplinColor.Surface,
                                  cursor: showLoader ? 'none' : 'pointer',
                                }}
                              >
                                {obj.documentDetails ? 'UPDATE' : 'UPLOAD'}
                              </button>
                            </div> */}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </div>
        </div>
      )}
      {showSnackbar && (
        <SnackBar
          message={showSnackBarMsg}
          show={showSnackbar}
          setShow={setShowSnackbar}
          // error={'secondary'}
        />
      )}
    </>
  );
};

export default LegalDocuments;
